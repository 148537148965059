export const FADE_DURATION: number = 1400;
export const FADE_DELAY: number = 100;

export const NETWORK = "sepolia";
export const networkInfo = {
  ethereum: {
    presaleAddress: "",
    usdtAddress: "",
    usdtDecimal: 6,
  },
  bsctestnet: {
    presaleAddress: "0xC4cBa79Aa27E0b7934DFF7dEdEB4070052a44a44",
    usdtAddress: "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
    usdtDecimal: 18,
  },
  sepolia: {
    presaleAddress: "0x8109CD2216Eb34B3eBB89778Af45657B9E35f99E",
    usdtAddress: "0x7169d38820dfd117c3fa1f22a697dba58d90ba06",
    usdtDecimal: 6,
  },
};

export const PRICE_API = {
  ethereum:
    "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd",
  bsctestnet:
    "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd",
};
