import { forwardRef } from "react";
import "./About.css";
import IconButton from "@mui/material/IconButton";
import Reason1 from "../assets/images/1.png";
import Reason2 from "../assets/images/2.png";
import Reason3 from "../assets/images/3.png";
import Reason4 from "../assets/images/4.png";
import Reason5 from "../assets/images/5.png";
import HoverCard from "../components/HoverCard";

const data = [
  {
    image: Reason1,
    title: "Deflationary Tokenomics:",
    content: "Tokens are burned to increase scarcity and value.",
  },
  {
    image: Reason2,
    title: "Community-Driven DAO:",
    content: "Token holders decide the project’s future.",
  },
  {
    image: Reason3,
    title: "Cross-Chain Compatibility:",
    content: "Designed to integrate with multiple blockchains.",
  },
  {
    image: Reason4,
    title: "DeFi Integrations:",
    content: "Earn rewards through staking and liquidity provisioning.",
  },
  {
    image: Reason5,
    title: "Viral Meme Potential:",
    content: "Leveraging the power of internet culture.",
  },
];

const cards = [
  {
    image:
      "https://cdn.pixabay.com/photo/2018/05/27/15/28/technology-3433708_960_720.jpg",
    header: "Transparency",
    content: "Hodor. Hodor hodor, hodor. Hodor hodor hodor hodor hodor.",
  },
  {
    image:
      "https://cdn.pixabay.com/photo/2019/11/23/04/52/matrix-4646234_960_720.jpg",
    header: "Community Engagement",
    content: "Hodor. Hodor hodor, hodor. Hodor hodor hodor hodor hodor.",
  },
  {
    image:
      "https://cdn.pixabay.com/photo/2013/11/20/09/35/background-213649_960_720.jpg",
    header: "Sustainable Growth",
    content: "Hodor. Hodor hodor, hodor. Hodor hodor hodor hodor hodor.",
  },
];

const About = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div
      ref={ref}
      className="flex flex-col gap-[20px] px-5 md:px-[100px] xl:px-[150px] text-center mt-[150px]"
    >
      <div className="font-normal flex flex-wrap justify-center items-center gap-2 tiny:gap-5 ">
        <div className="relative text-[35px] tiny:text-[40px] sm:text-[75px] ">
          <h1 className="titlered-shadow absolute top-0 left-0 w-full whitespace-nowrap">
            What is
          </h1>
          <h1 className="titlered z-10 whitespace-nowrap">What is</h1>
        </div>
        <div className="relative text-[26px] tiny:text-[35px] sm:text-[45px] md:text-[55px] lg:text-[65px] xl:text-[75px] ">
          <h1 className="text-green-500 titlegreen whitespace-nowrap">
            Evil Kermit Token ($EVLK)?
          </h1>
          <h1 className="text-green-500 titlegreen-shadow absolute top-0 left-0 w-full whitespace-nowrap">
            Evil Kermit Token ($EVLK)?
          </h1>
        </div>
      </div>
      <div className="flex gap-4 flex-col items-center">
        <p className="text-center text-[#C5DED1] text-[16px] leading-[24px] font-normal  py-2 max-w-[1024px] flex-1">
          Evil Kermit Token ($EVLK) is more than just a meme coin—it’s a
          movement. Inspired by the viral Evil Kermit meme, $EVLK combines
          blockchain technology with humor, offering deflationary tokenomics,
          community-driven governance, and cross-chain utility.
        </p>
        <div className="flex gap-4 flex-col xl:flex-row px-10 flex-1">
          <HoverCard cards={cards} />
          {/* <div className="key-box w-[250px] sm:w-[300px] flex-1">
            Transparency
          </div>
          <div className="key-box w-[250px] sm:w-[300px] flex-1">
            Community Engagement
          </div>
          <div className="key-box w-[250px] sm:w-[300px]  flex-1">
            Sustainable Growth
          </div> */}
        </div>
        <a className="link-a">Learn More About $EVLK</a>
      </div>

      <div className="font-normal flex flex-wrap justify-center items-center gap-2 tiny:gap-5 mt-20">
        <div className="relative text-[35px] tiny:text-[40px] sm:text-[75px] ">
          <h1 className="titlered-shadow absolute top-0 left-0 w-full whitespace-nowrap">
            How
          </h1>
          <h1 className="titlered z-10 whitespace-nowrap">How</h1>
        </div>
        <div className="relative text-[26px] tiny:text-[35px] sm:text-[45px] md:text-[55px] lg:text-[65px] xl:text-[75px] ">
          <h1 className="text-green-500 titlegreen whitespace-nowrap">
            Evil Kermit Stands out
          </h1>
          <h1 className="text-green-500 titlegreen-shadow absolute top-0 left-0 w-full whitespace-nowrap">
            Evil Kermit Stands out
          </h1>
        </div>
      </div>
      <div className="flex justify-start text-start flex-wrap gap-4 flex-col items-center text-[#C5DED1]">
        <div className="flex flex-wrap gap-5 justify-center items-center max-w-[800px]">
          {/* {data.map((reason, index) => (
            <div
              className="flex justify-start items-center long-bar  w-full"
              key={"Reason" + index}
            >
              <div className="img-part ">
                <IconButton sx={{ width: "100%" }}>
                  <img src={reason.image} />
                </IconButton>
              </div>
              <div className="desc-part text-[10px] tiny:text-[12px] sm:text-[14px] flex-1">
                <span className="text-[12px] tiny:text-[14px] sm:text-[16px] text-[#fff]">
                  {reason.title}
                </span>{" "}
                {reason.content}
              </div>
            </div>
          ))} */}
          {data.map((reason, index) => (
            <div
              className="flex flex-col  card2-box long-bar justify-around"
              key={"Reason" + index}
            >
              <div className="card2-title">{reason.title}</div>
              <div className="card2-divider"></div>
              <div className="card2-body">{reason.content}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default About;
