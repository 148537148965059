// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phase-card {
  border: 1px solid #333;
  border-left: 6px solid #333;
  border-radius: 5px;
  padding: 5px 10px;
  max-width: 400px;
}
`, "",{"version":3,"sources":["webpack://./src/page/Presale.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,2BAA2B;EAC3B,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".phase-card {\n  border: 1px solid #333;\n  border-left: 6px solid #333;\n  border-radius: 5px;\n  padding: 5px 10px;\n  max-width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
