import React, { forwardRef } from "react";
import diamond from "../assets/images/diamond.png";
import rocket from "../assets/images/rocket.png";
import handshake from "../assets/images/handshake.png";
import clock from "../assets/images/clock.png";
import NFTCarousel from "../components/NFTCarousel";
import nft1 from "../assets/images/nft1.jpg";
import nft2 from "../assets/images/nft2.jpg";
import { Fade } from "react-awesome-reveal";
import { FADE_DURATION, FADE_DELAY } from "../config";

const nftList = [
  {
    type: "image",
    source: nft1,
    title: "Hooded Whisperer",
    description:
      "Evil Kermit, hood up, glowing yellow eyes, and a sly smirk—ready to give you that terrible advice we all know you’ll listen to.",
    tagline: "Sometimes, the darkest advice comes in whispers.",
  },
  {
    type: "image",
    source: nft2,
    title: "Plotting Puppeteer",
    description:
      "Evil Kermit’s in full control, holding regular Kermit by the neck with puppet strings, giving off that “I’m in charge now” vibe.",
    tagline: "Sometimes, the darkest advice comes in whispers.",
  },
  {
    type: "video",
    source: "/res/nft3.mp4",
    title: "Tyrant of Chaos",
    description:
      "Evil Kermit’s holding regular Kermit up by the neck like he’s about to do something... and it’s not gonna be pretty. Pure chaos.",
    tagline:
      "The weak submit; the powerful conquer. Which side will you choose?",
  },
];

const NFTSection = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div
      className="px-5 sm:px-[100px] mt-[50px] md:mt-[250px] lg:mt-[50px] xl:mt-[150px]"
      ref={ref}
    >
      <div className=" font-normal text-center mt-20 flex flex-col items-center">
        <div className="relative">
          <h2 className="text-[30px] tiny:text-[40px] sm:text-[55px] titlered whitespace-nowrap">
            Explore the Power of :
          </h2>
          <h2 className="text-[30px] tiny:text-[40px] sm:text-[55px] titlered-shadow whitespace-nowrap">
            Explore the Power of :
          </h2>
        </div>
        <div className="relative">
          <h2 className="text-[20px] tiny:text-[34px] sm:text-[50px] text-green-500 titlegreen whitespace-nowrap">
            $EVLK NFTs
          </h2>
          <h2 className="text-[20px] tiny:text-[34px] sm:text-[50px] text-green-500 titlegreen-shadow whitespace-nowrap">
            $EVLK NFTs
          </h2>
        </div>
        {/* <p className="text-[12px] tiny:text-[15px] max-w-[1024px] text-center">
          Dive deeper into the dark side with our limited-edition Evil Kermit
          NFTs. Designed with striking visuals and unique traits, these NFTs are
          perfect for collectors and fans of the meme. Each one is a symbol of
          rebellion and creativity, embodying the essence of $EVLK.
        </p> */}
      </div>
      {/* <Fade cascade duration={FADE_DURATION} delay={FADE_DELAY}>
        <div className="font-normal text-center mt-20 flex flex-col md:flex-row justify-center gap-0 md:gap-5">
          <div className="relative">
            <h2 className="text-[32px] tiny:text-[50px] titlered whitespace-nowrap">
              Why Mint an
            </h2>
            <h2 className="text-[32px] tiny:text-[50px] titlered-shadow whitespace-nowrap">
              Why Mint an
            </h2>
          </div>
          <div className="relative">
            <h2 className="text-[32px] tiny:text-[50px] text-green-500 titlegreen whitespace-nowrap">
              Evil Kermit NFT?
            </h2>
            <h2 className="text-[32px] tiny:text-[50px] text-green-500 titlegreen-shadow whitespace-nowrap">
              Evil Kermit NFT?
            </h2>
          </div>
        </div>
      </Fade> */}

      <Fade cascade duration={FADE_DURATION} delay={FADE_DELAY}>
        <div className="flex flex-wrap justify-center gap-1 mt-10">
          <div className="flex justify-center mb-3 max-w-[250px]">
            <div className="flex flex-col text-center items-center">
              <div className="rounded-[50%] p-5 linear-background linear-border">
                <img src={diamond} alt="diamond icon" />
              </div>
              <h6 className="text-[16px] tiny:text-[18px] font-bold my-5">
                Unique Collectibles
              </h6>
              <p className="text-[14px] tiny:text-[16px] text-[#C5DED1]">
                Exclusive Evil Kermit NFTs designed for collectors and
                enthusiasts.
              </p>
            </div>
          </div>
          <div className="flex justify-center mb-3  max-w-[250px]">
            <div className="flex flex-col text-center items-center">
              <div className="rounded-[50%] p-5 linear-background linear-border">
                <img src={rocket} alt="rocket icon" />
              </div>
              <h6 className="text-[16px] tiny:text-[18px] font-bold my-5">
                Gamified Rewards
              </h6>
              <p className="text-[14px] tiny:text-[18px] text-[#C5DED1]">
                Earn NFTs through participation in community events and staking.
              </p>
            </div>
          </div>
          <div className="flex justify-center mb-3  max-w-[250px]">
            <div className="flex flex-col text-center items-center">
              <div className="rounded-[50%] p-5 linear-background linear-border">
                <img src={handshake} alt="diamond icon" />
              </div>
              <h6 className="text-[16px] tiny:text-[18px] font-bold my-5">
                Cross-Platform Utility
              </h6>
              <p className="text-[14px] tiny:text-[18px] text-[#C5DED1]">
                Use $EVLK NFTs across compatible platforms for added perks.
              </p>
            </div>
          </div>
          <div className="flex justify-center mb-3  max-w-[250px]">
            <div className="flex flex-col text-center items-center">
              <div className="rounded-[50%] p-5 linear-background linear-border">
                <img src={clock} alt="diamond icon" className="w-[50px]" />
              </div>
              <h6 className="text-[16px] tiny:text-[18px] font-bold my-5">
                Limited Editions
              </h6>
              <p className="text-[14px] tiny:text-[18px] text-[#C5DED1]">
                Rare and special edition NFTs to boost engagement and value.
              </p>
            </div>
          </div>
        </div>
      </Fade>
      <Fade cascade duration={FADE_DURATION} delay={FADE_DELAY}>
        <div className="mt-[150px] px-5 md:px-[100px]">
          <div className="relative">
            <h2 className="text-[32px] tiny:text-[50px] text-green-500 titlegreen whitespace-nowrap">
              Evil Kermit NFT?
            </h2>
            <h2 className="text-[32px] tiny:text-[50px] text-green-500 titlegreen-shadow whitespace-nowrap">
              Evil Kermit NFT?
            </h2>
          </div>
          <div className="w-full flex justify-center">
            <p className="text-[14px] tiny:text-[16px] xl:text-[18px] max-w-[950px] text-center text-[#C5DED1]">
              Minting will be available exclusively during the pre-sale phase,
              giving our earliest supporters the chance to secure the rarest
              NFTs. Don't miss the opportunity to own a piece of history before
              it’s gone.
            </p>
          </div>

          {/* <div className="grid grid-cols-3">
          <NFTCard />
        </div> */}
          <NFTCarousel nftList={nftList} />
          <p className="text-md tiny:text-xl mt-10 text-center text-[#C5DED1]">
            Be the first to mint these exclusive NFTs and cement your place in
            the $EVLK community. Limited availability—don’t wait!"
          </p>
          <div className="flex items-center flex-col sm:flex-row justify-center gap-5 text-black font-bold leading-[27px] text-[18px] mt-5">
            <button className="button-background text-[#fff]  w-[220px] h-[40px]">
              Mint Your NFT Now
            </button>
            <button className="button-background text-[#fff] w-[220px] h-[40px]">
              Learn More
            </button>
          </div>
        </div>
      </Fade>
    </div>
  );
});

export default NFTSection;
