import React, { forwardRef } from "react";
import RoadMap from "../assets/images/roadmap.svg";
import RoadMapImage from "../assets/images/roadmap-sketch.svg";
import RoadmapCharacter from "../assets/images/RoadmapCharacter.png";
import LineTable from "../assets/images/line-table.svg";
import Play from "../assets/images/play.svg";
import { Fade } from "react-awesome-reveal";
import { FADE_DURATION, FADE_DELAY } from "../config";
import "./Roadmap.css";

const TokenomicsHand = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div
      ref={ref}
      className="w-full mt-[150px] px-5 md:px-[100px] xl:px-[150px] mb-[200px]"
    >
      {/* <img src={RoadMap} alt="RoadMap" className="float-right w-[100vw]" /> */}

      <div>
        <div className="relative">
          <h2 className="text-[50px] tiny:text-[75px] titlegreen">Roadmap</h2>
          <h2 className="text-[50px] tiny:text-[75px] titlegreen-shadow">
            Roadmap
          </h2>
        </div>
        <Fade cascade duration={FADE_DURATION} delay={FADE_DELAY}>
          <div className="relative h-[700px]">
            <img src={RoadmapCharacter} alt="roadmap-image" className="svg1" />
            <img src={LineTable} className="svg2" />
            {/* Q1 */}
            <div
              className="absolute top-[1%] left-[3rem] lg:top-[30%] lg:left-18 
            xl:left-[4rem] xl:top-[30%] 2xl:left-[5rem] 2xl:top-[35%]  3xl:left-[7rem] 3xl:top-[41%] max-w-[340px]"
            >
              <div className="flex items-center">
                <img src={Play} alt="play" className="w-6 h-6" />
                <div className="relative">
                  <h2 className="text-[2.6rem] bg-green-500 titlegreen leading-[84px]">
                    Q1, 2024
                  </h2>
                  <h2 className="text-[2.6rem] bg-green-500 titlegreen-shadow leading-[84px]">
                    Q1, 2024
                  </h2>
                </div>
              </div>

              <div className="-mt-2 overflow-hidden max-w-[240px] lg:max-w-[280px]">
                <ul className="text-[#C5DED1] leading-[28px] text-[12px] xl:text-[14px] 2xl:text-[18px] 3xl:text-[20px] list-inside list-disc">
                  <li>Launch EvilKermit Token</li>
                  <li>Initial Marketing Campaigns</li>
                  <li>Presale and Community Building</li>
                </ul>
              </div>
            </div>
            <div className="absolute bottom-[-7%] left-[16%] md:bottom-[-10%]  lg1:bottom-[-6%] lg1:left-[17%] lg:bottom-[12%] lg:left-[14%] xl:left-[16%] xl:bottom-[7%] 2xl:bottom-[-10%] 2xl:left-[15%] 3xl:bottom-[-18%] 3xl:left-[15%] max-w-[270px] 2xl:max-w-[340px]">
              <div className="flex items-center ">
                <img src={Play} alt="play" className="w-6 h-6" />
                <div className="relative">
                  <h2 className="text-[2.6rem] bg-green-500 titlegreen leading-[84px]">
                    Q2, 2024
                  </h2>{" "}
                  <h2 className="text-[2.6rem] bg-green-500 titlegreen-shadow leading-[84px]">
                    Q2, 2024
                  </h2>
                </div>
              </div>

              <div className="-mt-2 overflow-hidden max-w-[250px] lg:max-w-[280px]">
                <ul className="text-[#C5DED1] leading-[28px] text-[12px] xl:text-[14px] 2xl:text-[18px] 3xl:text-[20px]  list-inside list-disc custom-list">
                  <li>Launch EvilKermit Token</li>
                  <li>Initial Marketing Campaigns</li>
                  <li>Presale and Community Building</li>
                </ul>
              </div>
            </div>
            {/* Q2 */}
            <div className="absolute bottom-[13%] left-[39%] lg1:bottom-[14%] lg1:left-[41%] lg:bottom-[30%] lg:left-[40%] xl:left-[39%] xl:bottom-[30%] 2xl:left-[40%] 2xl:bottom-[13%] 3xl:left-[41%] 3xl:bottom-[5%] max-w-[270px] 2xl:max-w-[340px]">
              <div className="flex items-center ">
                <img src={Play} alt="play" className="w-6 h-6" />
                <div className="relative">
                  <h2 className="text-[2.6rem] bg-green-500 titlegreen leading-[84px]">
                    Q3, 2024
                  </h2>
                  <h2 className="text-[2.6rem] bg-green-500 titlegreen-shadow leading-[84px]">
                    Q3, 2024
                  </h2>
                </div>
              </div>

              <div className="-mt-2 overflow-hidden max-w-[250px] lg:max-w-[230px] xl:max-w-[280px]">
                <ul className="text-[#C5DED1] leading-[28px] text-[12px] xl:text-[14px] 2xl:text-[18px] 3xl:text-[20px]  list-inside list-disc custom-list">
                  <li>Launch EvilKermit Token</li>
                  <li>Initial Marketing Campaigns</li>
                  <li>Presale and Community Building</li>
                </ul>
              </div>
            </div>
            {/* Q3 */}
            <div className="absolute bottom-[-5%] right-[5%] md1:bottom-[-10%] md1:right-[5%] lg:right-[10%] lg:bottom-[5%]  xl:bottom-[2%] xl:right-[10%] 2xl:bottom-[-18%] 2xl:right-[12%] 3xl:bottom-[-25%] 3xl:right-[17%] max-w-[270px] 2xl:max-w-[340px]">
              <div className="flex items-center">
                <img src={Play} alt="play" className="w-6 h-6" />
                <div className="relative">
                  <h2 className="text-[2.6rem] bg-green-500 titlegreen leading-[84px]">
                    Q4, 2024
                  </h2>{" "}
                  <h2 className="text-[2.6rem] bg-green-500 titlegreen-shadow leading-[84px]">
                    Q4, 2024
                  </h2>
                </div>
              </div>

              <div className="-mt-2 overflow-hidden max-w-[250px] lg:max-w-[280px]">
                <ul className="text-[#C5DED1] leading-[28px] text-[12px] xl:text-[14px] 2xl:text-[18px] 3xl:text-[20px] list-inside list-disc custom-list">
                  <li>Launch EvilKermit Token</li>
                  <li>Initial Marketing Campaigns</li>
                  <li>Presale and Community Building</li>
                </ul>
              </div>
            </div>
            {/* Q4 */}
          </div>
        </Fade>
      </div>
    </div>
  );
});

export default TokenomicsHand;
