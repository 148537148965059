import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { useMediaQuery } from "@mui/material";
import { mobileAndDesktopOS, valueFormatter } from "./allocation";

const PieAnimation = () => {
  const [radius, setRadius] = React.useState(50);
  const isTiny = useMediaQuery("(min-width: 470px)");
  const isSm = useMediaQuery("(min-width: 640px)");
  const isMd = useMediaQuery("(min-width: 768px)");
  const isXl = useMediaQuery("(min-width: 1280px)");

  const [itemNb, setItemNb] = React.useState(5);
  const [skipAnimation, setSkipAnimation] = React.useState(false);

  const handleItemNbChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue !== "number") {
      return;
    }
    setItemNb(newValue);
  };
  const handleRadius = (event: Event, newValue: number | number[]) => {
    if (typeof newValue !== "number") {
      return;
    }
    setRadius(newValue);
  };

  const vivid = ["#204D00", "#3D7317", "#63993D", "#87BB62", "#AFDC8F"];

  const data = [
    { label: "Public Sale", value: 40, color: vivid[0] },
    { label: "Liquidity Pool", value: 20, color: vivid[1] },
    { label: "Ecosystem & Development", value: 15, color: vivid[2] },
    { label: "Marketing & Community", value: 15, color: vivid[3] },
    { label: "Team & Advisors", value: 10, color: vivid[4] },
  ];

  const sizing = {
    margin: { right: 5 },
    height: isXl ? 450 : isMd ? 350 : isSm ? 300 : isTiny ? 250 : 200,
    legend: { hidden: true },
  };

  return (
    <Box sx={{ width: "100%" }}>
      <PieChart
        series={[
          {
            data: data,
            innerRadius: 26,
            paddingAngle: 2,
            cornerRadius: 7,
            startAngle: -140,
            endAngle: 225,
            highlightScope: { fade: "global", highlight: "item" },
            faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
            arcLabel: (params) => `${String(params.value)}%`,
            arcLabelMinAngle: 20,
            valueFormatter,
          },
        ]}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: "white",
            fontSize: isXl ? 36 : isMd ? 32 : isSm ? 28 : isTiny ? 24 : 20,
            fontFamily: "StinkySlime",
          },
          [`& path`]: {
            strokeWidth: 0,
          },
        }}
        {...sizing}
        skipAnimation={false}
      />
      {/* <FormControlLabel
        checked={skipAnimation}
        control={
          <Checkbox
            onChange={(event) => setSkipAnimation(event.target.checked)}
          />
        }
        label="skipAnimation"
        labelPlacement="end"
      />
      <Typography id="input-item-number" gutterBottom>
        Number of items
      </Typography>
      <Slider
        value={itemNb}
        onChange={handleItemNbChange}
        valueLabelDisplay="auto"
        min={1}
        max={8}
        aria-labelledby="input-item-number"
      /> */}
    </Box>
  );
};

export default PieAnimation;
