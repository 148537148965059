import { forwardRef } from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#207824",
  "&:hover": {
    backgroundColor: green[700],
  },
}));
const Whitepaper = forwardRef<HTMLDivElement>((_, ref) => {
  const handleDownload = () => {
    const link = document.createElement("a"); // Create an anchor tag
    link.href = "/res/pdf/whitepaper.pdf"; // Path to the whitepaper PDF file
    link.download = "whitepaper.pdf"; // The name the file will have when downloaded
    link.click();
  };
  return (
    <div ref={ref} className="mt-[150px]  px-5 md:px-[100px]">
      <div className="relative">
        <h2 className="text-[24px] tiny:text-[35px] sm:text-[55px] titlegreen">
          Discover the Full Details
        </h2>
        <h2 className="text-[24px] tiny:text-[35px] sm:text-[55px] titlegreen-shadow">
          Discover the Full Details
        </h2>
      </div>
      <div className="relative">
        <h2 className="text-[28px] tiny:text-[35px] sm:text-[55px] titlegreen">
          of Evil Kermit Token!
        </h2>
        <h2 className="text-[28px] tiny:text-[35px] sm:text-[55px] titlegreen-shadow">
          of Evil Kermit Token!
        </h2>
      </div>
      <div className="text-center px-2 md:px-20 py-2 md:py-10">
        <p className="text-[16px] tiny:text-[18px] text-[#C5DED1]">
          Learn about $EVLK's deflationary tokenomics, governance structure,
          cross-chain integrations, and roadmap in our comprehensive whitepaper.
        </p>
      </div>
      <div className="text-center mt-10">
        <ColorButton
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudDownloadIcon />}
          sx={{ width: "100%", height: "55px" }}
          onClick={handleDownload}
        >
          <span className="font-bold">Download the Whitepaper</span>
        </ColorButton>
      </div>
    </div>
  );
});

export default Whitepaper;
