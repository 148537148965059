import { forwardRef } from "react";
import Telegram from "../assets/images/Telegram.png";
import Discode from "../assets/images/Discode.png";
import Twitter from "../assets/images/Twitter.png";
import "./Contact.css";

const Community = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div ref={ref} className="mt-[150px]">
      <div className="relative">
        <h1 className="text-[25px] tiny:text-[40px] md:text-[55px] titlegreen leading-[75px] font-[400]">
          Join the Evil Kermit Army
        </h1>
        <h1 className="text-[25px] tiny:text-[40px] md:text-[55px] titlegreen-shadow leading-[75px] font-[400] absolute top-0 left-0">
          Join the Evil Kermit Army
        </h1>
      </div>

      <div className="flex flex-col justify-center items-center mt-8 px-5">
        <div className="highlight text-[14px] tiny:text-[16px] md:text-[18px] w-full max-w-[650px]">
          Regular updates via Discord, Twitter, and Telegram.
        </div>
        <div className="highlight text-[14px] tiny:text-[16px] md:text-[18px] w-full max-w-[650px]">
          Airdrops and rewards for loyal members.
        </div>
        <div className="highlight text-[14px] tiny:text-[16px] md:text-[18px] w-full max-w-[650px]">
          Community-driven initiatives like meme competitions.
        </div>
      </div>
      <div>
        <div className="flex flex-col gap-[30px] mt-20">
          <div className="flex flex-col justify-center items-center gap-[10px]">
            <div className="font-bold text-[18px] sm:text-[24px] leading-[36px]">
              Join our community
            </div>
            <div className="font-normal text-[12px] sm:text-[16px] leading-[24px] text-[#C5DED1]">
              Stay updated and join the conversation!
              <br />
              Follow us on our official channels:
            </div>
          </div>
          <div className="flex gap-[20px] justify-center mt-[20px]">
            <a href="https://t.me/evlkermit" target="_blank" rel="noreferrer">
              <img src={Telegram} alt="" />
            </a>
            <a
              href="https://discord.gg/5aseXpt8"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Discode} alt="" />
            </a>
            <a href="https://x.com/EvlKermit" target="_blank" rel="noreferrer">
              <img src={Twitter} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Community;
