// Import React and necessary hooks
import React, { useState, useEffect, useRef } from "react";
import "./HoverCard.css";

interface CardProps {
  dataImage: string;
  children: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ dataImage, children }) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [mouseX, setMouseX] = useState<number>(0);
  const [mouseY, setMouseY] = useState<number>(0);

  useEffect(() => {
    if (cardRef.current) {
      setWidth(cardRef.current.offsetWidth);
      setHeight(cardRef.current.offsetHeight);
    }
  }, []);

  const handleMouseMove = (e: React.MouseEvent) => {
    if (cardRef.current) {
      const bounds = cardRef.current.getBoundingClientRect();
      setMouseX(e.clientX - bounds.left - width / 2);
      setMouseY(e.clientY - bounds.top - height / 2);
    }
  };

  const handleMouseLeave = () => {
    setMouseX(0);
    setMouseY(0);
  };

  const cardStyle: React.CSSProperties = {
    transform: `rotateY(${(mouseX / width) * 30}deg) rotateX(${
      -(mouseY / height) * 30
    }deg)`,
  };

  const cardBgStyle: React.CSSProperties = {
    transform: `translateX(${-(mouseX / width) * 40}px) translateY(${
      -(mouseY / height) * 40
    }px)`,
    backgroundImage: `url(${dataImage})`,
  };

  return (
    <div
      className="card-wrap"
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      ref={cardRef}
    >
      <div className="card" style={cardStyle}>
        <div className="card-bg" style={cardBgStyle}></div>
        <div className="card-info">{children}</div>
      </div>
    </div>
  );
};

interface CardData {
  image: string;
  header: string;
  content: string;
}

type HoverCardProps = {
  cards: CardData[];
};

const HoverCard = ({ cards }: HoverCardProps) => {
  return (
    <div className="app">
      <div className="container">
        {cards.map((card, index) => (
          <Card key={index} dataImage={card.image}>
            <h1>{card.header}</h1>
            <p>{card.content}</p>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default HoverCard;
