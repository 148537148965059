import React, { forwardRef } from "react";
// import MobileRoadMapImg from "../assets/images/mobile_roadmap.svg";
import MobileRoadMapImage from "../assets/images/MobileRoadMap.svg";
import RoadMapCharacter from "../assets/images/RoadmapCharacter.png";
import LineMobile from "../assets/images/line-mobile.svg";
import Play from "../assets/images/play.svg";
import "./MobileRoadMap.css";
const MobileRoadMap = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div className="mt-[150px]">
      {/* <img src={MobileRoadMapImg} alt="RoadMap" className="" /> */}
      <div>
        <div className="relative mb-10">
          <h2 className="text-[50px] md:text-[70px] titlegreen">Roadmap</h2>
          <h2 className="text-[50px] md:text-[70px] titlegreen-shadow">
            Roadmap
          </h2>
        </div>
        <div className="flex justify-center items-start">
          <div className="relative h-[650px] tiny:h-[1000px] sm:h-[1100px] md:h-[1200px] md1:h-[1400px] w-[320px] tiny:w-[500px] sm:w-[600px] md:w-[750px]">
            <img
              src={RoadMapCharacter}
              alt="roadmap-image"
              className="svg-mobile-1"
            />
            <img src={LineMobile} className="svg-mobile-2" />

            {/* Q1 */}
            <div className="absolute top-[8%] left-[25px] tiny:top-[13%] tiny:left-[13%] sm:top-[18%] sm:left-[15%] md:left-[16%] md:top-[20%] md1:top-[18%] md1:left-[14%]  max-w-[200px] tiny:max-w-[220px] sm:max-w-[280px]">
              <div className="flex items-start mb-2">
                <div className="flex items-center">
                  <img src={Play} alt="play" className="w-6 h-6" />
                  <div className="relative">
                    <h2 className="text-[22px] tiny:text-[30px] sm:text-[35px] md:text-[40px] titlegreen whitespace-nowrap">
                      Q1, 2024
                    </h2>
                    <h2 className="text-[22px] tiny:text-[30px] sm:text-[35px] md:text-[40px] titlegreen-shadow  whitespace-nowrap">
                      Q1, 2024
                    </h2>
                  </div>
                </div>
              </div>

              <div className="-mt-2 ml-3">
                <ul className="text-[#C5DED1] list-inside list-disc text-[10px] tiny:text-[12px] sm:text-[14px] md:text-[16px] md1:text-[17px] min-w-[180px] leading-[16px] tiny:leading-[22px] sm:leading-[26px]">
                  <li>Launch EvilKermit Token</li>
                  <li>Initial Marketing Campaigns</li>
                  <li>Presale and Community Building</li>
                </ul>
              </div>
            </div>

            {/* Q2 */}
            <div className="absolute top-[27%] right-[12%] tiny:top-[32%] tiny:right-[10%] sm:top-[38%] sm:right-[12%] md:right-[14%] md:top-[43%] md1:top-[43%] md1:right-[15%]  max-w-[200px] tiny:max-w-[220px] sm:max-w-[280px]">
              <div className="flex items-center mb-2">
                <img src={Play} alt="play" className="w-6 h-6" />
                <div className="relative">
                  <h2 className="text-[22px] tiny:text-[30px] sm:text-[35px] md:text-[40px] titlegreen ">
                    Q2, 2024
                  </h2>
                  <h2 className="text-[22px] tiny:text-[30px] sm:text-[35px] md:text-[40px] titlegreen-shadow ">
                    Q2, 2024
                  </h2>
                </div>
              </div>

              <div className="-mt-2 ml-3">
                <ul className="text-[#C5DED1] list-inside list-disc text-[10px] tiny:text-[12px] sm:text-[14px] md:text-[16px] md1:text-[17px] min-w-[180px] leading-[16px] tiny:leading-[22px] sm:leading-[26px]">
                  <li>Launch EvilKermit Token</li>
                  <li>Initial Marketing Campaigns</li>
                  <li>Presale and Community Building</li>
                </ul>
              </div>
            </div>

            {/* Q3 */}
            <div className="absolute bottom-[43%] right-[-2%] tiny:bottom-[35%] tiny:right-[4%] sm:bottom-[30%] sm:right-[8%] md:right-[14%] md:bottom-[22%] md1:bottom-[26%] md1:right-[15%]  max-w-[180px] tiny:max-w-[220px] sm:max-w-[280px]">
              <div className="flex items-center  mb-2">
                <img src={Play} alt="play" className="w-6 h-6" />
                <div className="relative">
                  <h2 className="text-[22px] tiny:text-[30px] sm:text-[35px] md:text-[40px] titlegreen">
                    Q3, 2024
                  </h2>
                  <h2 className="text-[22px] tiny:text-[30px] sm:text-[35px] md:text-[40px] titlegreen-shadow">
                    Q3, 2024
                  </h2>
                </div>
              </div>

              <div className="-mt-2 ml-3">
                <ul className="text-[#C5DED1] list-inside list-disc text-[10px] tiny:text-[12px] sm:text-[14px] md:text-[16px] md1:text-[17px] min-w-[180px] leading-[16px] tiny:leading-[22px] sm:leading-[26px]">
                  <li>Launch EvilKermit Token</li>
                  <li>Initial Marketing Campaigns</li>
                  <li>Presale and Community Building</li>
                </ul>
              </div>
            </div>

            {/* Q4 */}
            <div className="absolute bottom-[26%] right-[8%] tiny:bottom-[18%] tiny:right-[4%] sm:bottom-[13%] sm:right-[13%] md:right-[14%] md:bottom-[3%] md1:bottom-[8%] md1:right-[10%]  max-w-[180px] tiny:max-w-[220px] sm:max-w-[280px]">
              <div className="flex items-center mb-2 ">
                <img src={Play} alt="play" className="w-6 h-6" />
                <div className="relative">
                  <h2 className="text-[22px] tiny:text-[30px] sm:text-[35px] md:text-[40px] titlegreen ">
                    Q4, 2024
                  </h2>
                  <h2 className="text-[22px] tiny:text-[30px] sm:text-[35px] md:text-[40px] titlegreen-shadow ">
                    Q4, 2024
                  </h2>
                </div>
              </div>

              <div className="-mt-2 ml-3">
                <ul className="text-[#C5DED1] list-inside list-disc text-[10px] tiny:text-[12px] sm:text-[14px] md:text-[16px] md1:text-[17px] min-w-[180px] leading-[16px] tiny:leading-[22px] sm:leading-[26px]">
                  <li>Launch EvilKermit Token</li>
                  <li>Initial Marketing Campaigns</li>
                  <li>Presale and Community Building</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MobileRoadMap;
