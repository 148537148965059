/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef } from "react";
import Button from "../components/Button";
import EvilKermitBackground1 from "../assets/images/bg.png";
import "../App.css";
import Presale from "../components/Presale";
import "./header.css";

const EvilKermitLanding = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div ref={ref}>
      <div className="relative landing-page grid grid-rows-[auto_1fr] ">
        {/* Navigation */}
        <div className="col-start-1 row-start-1 flex  flex-col items-center ">
          <div className="text-center mt-[100px]">
            <div className="relative logo-text">
              <h1 className="text-[55px] sm:text-[75px] md:text-[80px] font-bold logofont logo-shadow">
                EvilKermit
              </h1>
              <h1 className="text-[55px] sm:text-[75px] md:text-[80px] font-bold logofont logo-text-top-layer absolute top-0 left-0">
                EvilKermit
              </h1>
              <div className="text-[55px] sm:text-[75px] md:text-[80px] font-bold logofont masked-logo-text absolute top-0 left-0">
                EvilKermit
              </div>
            </div>

            <div className="text-[8px] mx-2 sm:text-[12px] font-[600] text-gray-200 mt-[-20px] md:mt-[-20px] leading-[24px] font-ppoppins">
              Embrace Your Inner Evil, Invest in EvilKermit.
            </div>
          </div>
          {/* Buttons Section */}
          <div className="flex gap-6 mb-16 mt-[-310px] tiny:mt-[-330px] justify-start px-5">
            <Presale />
          </div>
          {/* Hero Image Section */}
          {/* <div className="flex mt-[-400px]">
            <img
              src={EvilKermitHero}
              alt="Evil Kermit Character"
              className="w-full mt-auto object-cover scale-[1.8] mt-[160px]"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
});

export default EvilKermitLanding;
