/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Footimage from "../assets/images/Footimage.png";
import Footlogo from "../assets/images/Foot-logo.png";
import Logo from "../assets/images/logo.svg";
import Telegram from "../assets/images/Telegram-mini.png";
import Discode from "../assets/images/Discode-mini.png";
import Twitter from "../assets/images/Twitter-mini.png";
import "../App.css";

const FootPage: React.FC = () => {
  return (
    <div className="footer-background">
      <div className="flex justify-center z-[-1] h-[200px] tiny:h-[250px] md:h-[400px]"></div>
      <div className=" bottom-0 left-0 w-full h-full relative">
        <div className="flex flex-col justify-center items-center footerbackground border-t border-t-[#C5DED140] py-10 px-2 sm:px-20 pb-5">
          <div className="flex justify-around flex-col lg:justify-round gap-10 lg:gap-0 items-center lg:flex-row w-full">
            <div className="flex flex-col gap-[10px] w-[342px] items-center md1:items-start text-center md1:text-start">
              <div className="flex items-center gap-[10px] text-[30px] leading-[24px]">
                <img src={Logo} alt="logo" className="w-[40px]"></img>
                <div className="font-[400] text-[30px] leading-[24px] logofont">
                  EvilKermit
                </div>
              </div>
              <div className="leading-[36px] text-[18px] sm:text-[24px] font-bold">
                Embrace Your Inner Evil
              </div>
              <div className="text-[14px] leading-[17px] font-bold text-start">
                Disclaimer
              </div>
              <div
                className="text-[12px] leading-[14px] font-[400] text-[#C5DED1] max-w-[268px]"
                style={{ fontStyle: "italic" }}
              >
                EvilKermit is a community-driven, meme-inspired project and
                should be considered a high-risk investment. Always do your own
                research.
              </div>
            </div>
            <div className="flex flex-nowrap gap-10 sm:gap-16 md1:gap-8 text-nowrap">
              <div className="text-[13px] sm:text-[14px] flex flex-col gap-[20px]">
                <div className="text-[14px] leading-[17px] font-bold">
                  Quick Links
                </div>
                <div className="flex flex-col gap-[16px] items-start">
                  <div className="font-[400] leading-[17px] text-[#C5DED1]">
                    Home
                  </div>
                  <div className="font-[400] leading-[17px] text-[#C5DED1]">
                    Tokenomics
                  </div>
                  <div className="font-[400] leading-[17px] text-[#C5DED1]">
                    Roadmap
                  </div>
                  <div className="font-[400] leading-[17px] text-[#C5DED1]">
                    Pre-Sale
                  </div>
                  <div className="font-[400] leading-[17px] text-[#C5DED1]">
                    Contact Us
                  </div>
                </div>
              </div>
              <div className="text-[13px] sm:text-[14px] flex flex-col gap-[20px] items-start">
                <div className="text-[14px] leading-[17px] font-bold">
                  Community
                </div>
                <div className="flex gap-[10px] items-center">
                  <div className="w-[25px]">
                    <img src={Telegram} alt="logo" className="w-[25px]"></img>
                  </div>
                  <a
                    href="https://t.me/evlkermit"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="flex leading-[17px] font-[400] text-[#C5DED1]">
                      Telegram
                    </div>
                  </a>
                </div>
                <div className="flex gap-[10px] items-center">
                  <div className="w-[25px]">
                    <img src={Discode} alt="logo" className="w-[25px]"></img>
                  </div>
                  <a href="https://discord.gg/5aseXpt8" target="_blank">
                    <div className="flex leading-[17px] font-[400] text-[#C5DED1]">
                      Discord
                    </div>
                  </a>
                </div>
                <div className="flex gap-[10px] items-center">
                  <div>
                    <img src={Twitter} alt="logo"></img>
                  </div>
                  <a
                    href="https://x.com/EvlKermit"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="flex leading-[17px] font-[400] text-[#C5DED1]">
                      Twitter
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-[20px]">
              <div className="text-[14px] leading-[17px] font-bold">
                Contact Us
              </div>
              <div className="flex flex-col gap-[12px]">
                <div className="text-[14px] font-[400] max-w-[268px] leading-[21px] text-[#C5DED1]">
                  For inquiries or support, please reach out via our Contact
                  Form or email us at{" "}
                  <span className="text-[#2EA92E]">
                    <a href="support@evilKermit.com">support@evilKermit.com</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center text-[12px] sm:text-[14px] whitespace-nowrap font-bold leading-4 mb-[10px] mx-[200px] mt-10">
            © 2024 EvilKermit. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default FootPage;
