// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlight {
  border: 1px solid #333;
  border-left: 6px solid #333;
  background: #1a1a1a8f;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 5px 10px;
  backdrop-filter: blur(10px);
}
`, "",{"version":3,"sources":["webpack://./src/page/Contact.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,2BAA2B;EAC3B,qBAAqB;EACrB,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,2BAA2B;AAC7B","sourcesContent":[".highlight {\n  border: 1px solid #333;\n  border-left: 6px solid #333;\n  background: #1a1a1a8f;\n  border-radius: 5px;\n  padding: 10px 20px;\n  margin: 5px 10px;\n  backdrop-filter: blur(10px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
