// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.key-box {
  border: 1px solid #333;
  border-radius: 5px;
  padding: 5px 20px;
  color: #e7e7e7;
  background-color: #3b3b3b88;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-a {
  color: #19c919;
  cursor: pointer;
}

.long-bar {
  border: 1px solid #555;
  border-left: 6px solid #444;
  border-radius: 5px;
  background: #333;
}

.img-part {
  background: #222;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.desc-part {
  padding: 5px 10px;
}

.card2-box {
  padding: 20px 10px;
  width: 250px;
  height: 170px;
}

.card2-title {
  font-size: 16px;
  color: #e0e0e0;
}

.card2-body {
  font-size: 14px;
  color: #aaa;
}

.card2-divider {
  border: 1px solid #000000;
  border-radius: 10px;
  box-shadow: #aaa 5px 5px;
}
`, "",{"version":3,"sources":["webpack://./src/page/About.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,cAAc;EACd,2BAA2B;EAC3B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,wBAAwB;AAC1B","sourcesContent":[".key-box {\n  border: 1px solid #333;\n  border-radius: 5px;\n  padding: 5px 20px;\n  color: #e7e7e7;\n  background-color: #3b3b3b88;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.link-a {\n  color: #19c919;\n  cursor: pointer;\n}\n\n.long-bar {\n  border: 1px solid #555;\n  border-left: 6px solid #444;\n  border-radius: 5px;\n  background: #333;\n}\n\n.img-part {\n  background: #222;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 50px;\n  height: 50px;\n}\n\n.desc-part {\n  padding: 5px 10px;\n}\n\n.card2-box {\n  padding: 20px 10px;\n  width: 250px;\n  height: 170px;\n}\n\n.card2-title {\n  font-size: 16px;\n  color: #e0e0e0;\n}\n\n.card2-body {\n  font-size: 14px;\n  color: #aaa;\n}\n\n.card2-divider {\n  border: 1px solid #000000;\n  border-radius: 10px;\n  box-shadow: #aaa 5px 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
