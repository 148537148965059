import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import NFTCard from "./NFTCard";
import NFTCardNew from "./NFTCardNew";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1320, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 920, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};
type NFTType = {
  type: string;
  source: any;
  title: string;
  description: string;
  tagline: string;
};

interface NFTCarouselProps {
  nftList: NFTType[];
}

const NFTCarousel: React.FC<NFTCarouselProps> = ({ nftList }) => {
  return (
    <Carousel responsive={responsive}>
      {nftList.map((nft, index) => {
        return <NFTCardNew nft={nft} key={"NFTCard" + index} />;
      })}
    </Carousel>
  );
};

export default NFTCarousel;
