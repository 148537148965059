import React, { useState, useEffect } from "react";
import "./WidgetProgressBar.css";

interface PresaleStatusProps {
  currentValue: number;
  maxValue: number;
}

const WidgetProgressBar: React.FC<PresaleStatusProps> = ({
  currentValue,
  maxValue,
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    console.log(progress, currentValue, maxValue);
    const progressPercentage = (currentValue / maxValue) * 100;
    setProgress(progressPercentage);
  }, [currentValue, maxValue]);

  return (
    <div>
      <div className="text-center text-[18px] leading-4 logofont">
        PRESALE STATUS
      </div>
      <div className="relative mt-3">
        <progress id="p2" value={progress} max={100}></progress>
        <div className="progress-bar-content">
          {currentValue.toLocaleString()} / {maxValue.toLocaleString()} EVLK{" "}
        </div>
      </div>
    </div>
  );
};

export default WidgetProgressBar;
