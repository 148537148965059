import WalletButton from "../components/WalletConnectButton";
import React, { useEffect, useState } from "react";
// import logo from "../assets/images/logo.png";
import logo_theme from "../assets/images/logo.svg";
interface NavbarProps {
  section1Ref: React.RefObject<HTMLDivElement>;
  section2Ref: React.RefObject<HTMLDivElement>;
  section3Ref: React.RefObject<HTMLDivElement>;
  section4Ref: React.RefObject<HTMLDivElement>;
  section5Ref: React.RefObject<HTMLDivElement>;
  section6Ref: React.RefObject<HTMLDivElement>;
  section7Ref: React.RefObject<HTMLDivElement>;
  section8Ref: React.RefObject<HTMLDivElement>;
  section9Ref: React.RefObject<HTMLDivElement>;
}

const Navbar: React.FC<NavbarProps> = ({
  section1Ref,
  section2Ref,
  section3Ref,
  section4Ref,
  section5Ref,
  section6Ref,
  section7Ref,
  section8Ref,
  section9Ref,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  let lastScrollY = 0;
  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY && currentScrollY > 100) {
      setIsVisible(false); // Hide navbar when scrolling down
    } else {
      setIsVisible(true); // Show navbar when scrolling up
    }
    lastScrollY = currentScrollY;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    setIsMenuOpen(false);
  };

  const navItemData = [
    {
      name: "Home",
      ref: section1Ref,
    },
    {
      name: "About",
      ref: section2Ref,
    },
    {
      name: "Presale",
      ref: section3Ref,
    },
    {
      name: "Tokenomics",
      ref: section4Ref,
    },
    {
      name: "Roadmap",
      ref: section5Ref,
    },
    {
      name: "Community",
      ref: section8Ref,
    },
    {
      name: "Contact",
      ref: section9Ref,
    },
  ];

  return (
    <nav
      className={`fixed top-0 left-0 w-full z-10 flex
       justify-between items-center px-10 xl:px-15 2xl:px-20 py-4 backdrop-blur-md transition-transform duration-300 ${
         isVisible ? "translate-y-0" : "-translate-y-full"
       }`}
    >
      <div className="flex justify-between items-center w-full">
        <div className="flex items-center gap-3 aligns-center">
          <img
            src={logo_theme}
            alt="Evil Kermit Logo"
            className="rounded-full w-[40px]"
          />
          <span className="text-white font-normal text-[23px] sm:text-[30px] leading-[24px] logofont ">
            EvilKermit
          </span>
        </div>
        <div className=" items-center gap-[10px] xl:gap-[20px] 2xl:gap-[40px] leading-[22px] xl:gap-[14px] 2xl:text-[18px] hidden lg:flex">
          {navItemData.map((navItem, index) => (
            <div
              className="text-white hover:text-green-400 focus:text-green-500 cursor-pointer red-shadow"
              onClick={() => scrollToSection(navItem.ref)}
              key={"Navbar" + index}
            >
              {navItem.name}
            </div>
          ))}
        </div>
        <div className="hidden lg:block">
          <WalletButton />
        </div>
        <button
          className="text-white text-[24px] lg:hidden"
          onClick={toggleMenu}
        >
          {isMenuOpen ? "✖" : "☰"}
        </button>
      </div>
      {isMenuOpen && (
        <div className="absolute w-full lg:hidden left-0 top-20 text-center bg-black/90 py-4 pb-6 z-20">
          <ul className="text-[24px] ">
            {navItemData.map((navItem, index) => (
              <li
                className="hover:cursor-pointer py-4 hover:text-[28px]"
                onClick={() => scrollToSection(navItem.ref)}
                key={"NavMobileItem" + index}
              >
                {navItem.name}
              </li>
            ))}
          </ul>
          <div className="flex justify-center">
            <div className="text-center max-w-[300px]">
              <WalletButton />
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
