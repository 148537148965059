// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roadmap-box {
  border-left: 6px solid #474747;
  padding: 10px 20px;
  border-radius: 5px;
  background: #141414;
}

.roadmap-container {
  position: relative;
  width: 100%;
  height: auto;
}

.svg1,
.svg2 {
  position: absolute;
  top: 30%; /* Same top position for both */
  left: 40%; /* Same left position for both */
}

.svg1 {
  width: 40%;
  transform: translate(32%, -13%); /* Center this image */
}

.svg2 {
  width: 100%;
  transform: translate(-40%, -20%); /* Adjust this as needed */
}
`, "",{"version":3,"sources":["webpack://./src/page/Roadmap.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;;EAEE,kBAAkB;EAClB,QAAQ,EAAE,+BAA+B;EACzC,SAAS,EAAE,gCAAgC;AAC7C;;AAEA;EACE,UAAU;EACV,+BAA+B,EAAE,sBAAsB;AACzD;;AAEA;EACE,WAAW;EACX,gCAAgC,EAAE,0BAA0B;AAC9D","sourcesContent":[".roadmap-box {\n  border-left: 6px solid #474747;\n  padding: 10px 20px;\n  border-radius: 5px;\n  background: #141414;\n}\n\n.roadmap-container {\n  position: relative;\n  width: 100%;\n  height: auto;\n}\n\n.svg1,\n.svg2 {\n  position: absolute;\n  top: 30%; /* Same top position for both */\n  left: 40%; /* Same left position for both */\n}\n\n.svg1 {\n  width: 40%;\n  transform: translate(32%, -13%); /* Center this image */\n}\n\n.svg2 {\n  width: 100%;\n  transform: translate(-40%, -20%); /* Adjust this as needed */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
