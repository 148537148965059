import React, { forwardRef } from "react";
import Handchart from "../assets/images/handchart.png";
import "../App.css";
import PieAnimation from "../components/PieChart";

const TokenomicsHand = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div
      ref={ref}
      className="w-full grid grid-cols-2 max-[854px]:flex flex-col items-center justify-center lg:items-start mt-[150px]"
    >
      <div className="flex flex-col gap-[20px] mt-[-40px] max-w-[478px] lg:max-w-full items-center text-center p-2 lg:items-start lg:text-start pl-0 lg:pl-[180px] xl:pl-[250px] 2xl:pl-[350px]">
        <div className="relative">
          <h1 className="text-[35px] tiny:text-[45px] md:text-[55px] titlegreen leading-[75px] font-[400] whitespace-nowrap">
            Token Allocation
          </h1>
          <h1 className="text-[35px] tiny:text-[45px] md:text-[55px] titlegreen-shadow leading-[75px] font-[400] absolute top-0 left-0 whitespace-nowrap">
            Token Allocation
          </h1>
        </div>

        <p className="text-[12px] tiny:text-[16px] leading-[20px] font-[400] text-[#C5DED1]">
          A transparent and balanced allocation designed to support EvilKermit's
          growth and stability:
        </p>
        <div className="flex flex-col gap-3">
          <div className="flex items-center space-x-3">
            <div className="w-4 h-4 rounded-sm bg-[#204D00]"></div>
            <span className="text-[#C5DED1] text-[12px] tiny:text-[18px] font-[400] leading-[20px]">
              40% Public Sale
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <div className="w-4 h-4 rounded-sm bg-[#3D7317]"></div>
            <span className="text-[#C5DED1] text-[12px] tiny:text-[18px] font-[400] leading-[20px]">
              20% Liquidity Pool
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <div className="w-4 h-4 rounded-sm bg-[#63993D]"></div>
            <span className="text-[#C5DED1] text-[12px] tiny:text-[18px] font-[400] leading-[20px]">
              15% Ecosystem & Development
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <div className="w-4 h-4 rounded-sm bg-[#87BB62]"></div>
            <span className="text-[#C5DED1] text-[12px] tiny:text-[18px] font-[400] leading-[20px]">
              15% Marketing & Community
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <div className="w-4 h-4 rounded-sm bg-[#AFDC8F]"></div>
            <span className="text-[#C5DED1] text-[12px] tiny:text-[18px] font-[400] leading-[20px]">
              10% Team & Advisors
            </span>
          </div>
        </div>

        <div className="max-w-xl flex flex-col justify-start items-start gap-3">
          <h2 className=" text-[16px] tiny:text-[24px] leading-[24px] font-bold text-white mb-3">
            Deflationary Mechanism:
          </h2>
          <p className=" text-[12px] tiny:text-[16px] leading-[20px] text-start font-[400] text-[#C5DED1]">
            - Milestone-based token burns.
          </p>
          <p className=" text-[12px] tiny:text-[16px] leading-[20px] text-start font-[400] text-[#C5DED1]">
            - Community-driven buybacks and burns.
          </p>
        </div>
      </div>
      <div>
        {/* <img
          src={Handchart}
          alt="handhchat"
          className="z-1 float-right sm:float-right md:float-right md:right-[0px] w-[300px] h-[180px] sm:w-[450px] sm:h-[280px]  md:w-[520px] md:h-[300px] lg:w-[560px] lg:h-[340px] xl:w-[700px] xl:h-[400px] 2xl:w-[800px] 2xl:h-[500px] 2xl:top-[-70px]  relative"
        /> */}
        <PieAnimation />
      </div>
    </div>
  );
});

export default TokenomicsHand;
