import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import EVLK from "../assets/images/presale-mark.png";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
// import { Fade } from "react-awesome-reveal";
import Fade from "@mui/material/Fade";
type PendingProps = {
  approveUsdt: boolean;
  approveUsdtSuccess: number; //  0: normal, 1: success, 2: failed
  confirmUsdt: boolean;
  confirmUsdtSuccess: number; // 0: normal, 1: success, 2: failed
};
const PendingUSDT = ({
  approveUsdt,
  approveUsdtSuccess,
  confirmUsdt,
  confirmUsdtSuccess,
}: PendingProps) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "#272727",
    border: "1px solid #454545",
    borderRadius: "5px",
    color: "#00d51c",
    outline: 0,
    boxShadow: 24,
    p: 1,
    pl: 4,
    textAlign: "center",
  };

  const buttonSx = {
    display: "flex",
    justifyContent: "center",
    ...(approveUsdtSuccess == 1 && {
      bgcolor: green[500],
    }),
    ...(approveUsdtSuccess == 2 && {
      bgcolor: "#ff0000",
    }),
  };

  const buttonSx1 = {
    display: "flex",
    justifyContent: "center",
    ...(confirmUsdtSuccess == 1 && {
      bgcolor: green[500],
    }),
    ...(confirmUsdtSuccess == 2 && {
      bgcolor: "#ff0000",
    }),
  };
  return (
    <Modal
      open={approveUsdt || confirmUsdt}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Fade in={approveUsdt || confirmUsdt}>
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box sx={{ m: 1, position: "relative" }}>
              <Fab
                aria-label="save"
                color="primary"
                size="small"
                sx={buttonSx}
                // onClick={handleButtonClick}
              >
                {approveUsdtSuccess == 1 && <CheckIcon fontSize="small" />}
                {approveUsdtSuccess == 0 && <span>1</span>}
                {approveUsdtSuccess == 2 && (
                  <PriorityHighIcon fontSize="small" />
                )}
              </Fab>
              {approveUsdt && approveUsdtSuccess == 0 && (
                <CircularProgress
                  size={48}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: -4,
                    left: -4,
                    zIndex: 1,
                  }}
                />
              )}
            </Box>
            <Typography id="keep-mounted-modal-description">
              {approveUsdt && approveUsdtSuccess == 0 && "Approving USDT"}
              {approveUsdtSuccess == 1 && "Approved USDT"}
              {approveUsdtSuccess == 2 && "Transaction failed"}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box sx={{ m: 1, position: "relative" }}>
              <Fab
                aria-label="save"
                color="primary"
                size="small"
                sx={buttonSx1}
                // onClick={handleButtonClick}
              >
                {confirmUsdtSuccess == 1 && <CheckIcon fontSize="small" />}
                {confirmUsdtSuccess == 0 && <span>2</span>}
                {confirmUsdtSuccess == 2 && (
                  <PriorityHighIcon fontSize="small" />
                )}
              </Fab>
              {confirmUsdt && confirmUsdtSuccess == 0 && (
                <CircularProgress
                  size={48}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: -4,
                    left: -4,
                    zIndex: 1,
                  }}
                />
              )}
            </Box>
            <Typography id="keep-mounted-modal-description">
              {!confirmUsdt &&
                confirmUsdtSuccess == 0 &&
                "Waiting for Approving USDT"}
              {confirmUsdt &&
                confirmUsdtSuccess == 0 &&
                "Confirming Transaction"}
              {confirmUsdtSuccess == 1 && "Confirmed Transaction"}
              {confirmUsdtSuccess == 2 && "Transaction failed"}
            </Typography>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default PendingUSDT;
