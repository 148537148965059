import React, { forwardRef } from "react";
import "../App.css";

const Contact = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div
      ref={ref}
      className="flex flex-col items-center justify-center mt-[150px] px-5 lg:px-[100px]"
    >
      <div className="text-[50px] md:text-[60px] xl:text-[75px] flex flex-col sm:flex-row leading-[75px] font-normal titlegreen text-center mb-5">
        <div className="relative">
          <h1 className="titlegreen whitespace-nowrap"> CONTACT US</h1>
          <h1 className="titlegreen-shadow whitespace-nowrap"> CONTACT US</h1>
        </div>
      </div>
      <div className="flex flex-col xl:gap-[100px] mt-10 xl:flex-row items-center xl:items-start">
        <div className="flex flex-col justify-between max-w-[580px] items-center xl:items-start text-center xl:text-start">
          <div className="flex flex-col gap-[30px] ">
            <div className="flex flex-col gap-[10px]">
              <div className="font-bold text-[18px] sm:text-[24px] leading-[36px] mt-4">
                Newsletter Signup
              </div>
              <div className="font-normal text-[12px] sm:text-[16px] leading-[24px] text-[#C5DED1] ">
                Never miss an update! Sign up for our newsletter to receive the
                latest news on EvilKermit, including roadmap milestones, presale
                alerts, and community events. Enter your email to join the
                EvilKermit community!
              </div>
            </div>
            <div className="flex flex-col gap-[15px] sm:gap-[20px]">
              <input
                type="text"
                className="bg-[#007D3A1A] border border-[#155E0F] rounded-[10px] px-[20px] py-[10px] placeholder-[#C5DED1] placeholder:text-[16px]"
                placeholder="Enter your email address"
              />
              <button className=" px-[20px] py-[10px] button-background font-[700] text-[18px] leading-[27px] text-[#0F0F0F]">
                Subscribe
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-[50px] py-[30px] px-[20px] contact-form border rounded-[50px]  border-[#056B03] max-w-[580px] mt-3">
          <form className="flex flex-col justify-between gap-[29px] ">
            <div className="text-center flex flex-col gap-[10px]">
              <div className="text-[28px] tiny:text-[32px] leading-[32px] font-[700]">
                Contact Form
              </div>
              <div className="text-[14px] tiny:text-[16px] leading-[24px] font-[400] text-[#C5DED1]">
                Have questions or need support? Reach out to us directly! Fill
                out the contact form below, and we’ll get back to you as soon as
                possible.
              </div>
            </div>
            <div className="flex flex-col gap-[10px] ">
              <input
                type="text"
                className="bg-[#007D3A1A] border border-[#155E0F] rounded-[10px] px-[20px] py-[10px] placeholder-[#C5DED1] placeholder:text-[16px]"
                placeholder="Enter your email address"
              />
              <input
                type="email"
                className="bg-[#007D3A1A] border border-[#155E0F] rounded-[10px] px-[20px] py-[10px] placeholder-[#C5DED1] placeholder:text-[16px]"
                placeholder="Enter your email address"
              />
              <textarea
                className="bg-[#007D3A1A] border border-[#155E0F] rounded-[10px] px-[20px] py-[10px] placeholder-[#C5DED1] placeholder:text-[16px] break-words min-h-[100px]"
                placeholder="Enter your message"
              ></textarea>
            </div>
            <div>
              <button className="button-background px-[20px] py-[10px] w-full font-[700] text-[18px] leading-[27px] text-[#0F0F0F]">
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});

export default Contact;
