import React, { useState, useEffect } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import ETH from "../assets/images/image 276.png";
import EVLK from "../assets/images/presale-mark.png";
// import EVLK from "../assets/images/presale-mark.svg";

import USDT from "../assets/images/USDT.png";
import abi from "../config/abis/Presale.json";
import erc20abi from "../config/abis/USDT.json";
import CountdownTimer from "../components/Countdown";
import PendingETH from "./PendingETH";
import PendingUSDT from "./PendingUSDT";
import {
  useWriteContract,
  useReadContract,
  useReadContracts,
  useAccount,
  useBalance,
  usePublicClient,
} from "wagmi";
import {
  encodeFunctionData,
  formatEther,
  parseEther,
  parseUnits,
  formatUnits,
} from "viem";
import formatterDecimal from "../utils/formatters/formatterDecimal";
import WidgetProgressBar from "./WidgetProgressBar";
import { networkInfo, NETWORK } from "../config";
import "./Presale.css";
const blurredInputClass = "opacity-50 cursor-not-allowed filter blur-[1px]";

const PresaleCA = networkInfo[NETWORK].presaleAddress as `0x${string}`;
const usdtAddress = networkInfo[NETWORK].usdtAddress as `0x${string}`;

const Presale = () => {
  const { address, isConnected } = useAccount();
  let tx;
  // const evilTokenAddress = "0xA186Df4C1783907Dc9D64EDF9DF8B84766F5aD2f";
  const [buyMethod, setBuyMethod] = useState<String>("ETH");
  const [buyPosition, setBuyPosition] = useState<String>("");
  const [valueCoin, setValueCoin] = useState<number>(0);
  const [estimatedToken, setEstimatedToken] = useState<number>(0);
  const [valueEthForBuying, setValueEthForBuying] = useState<number>(0);
  const [valueUsdtForBuying, setValueUsdtForBuying] = useState<number>(0);
  const [usdtAllowance, setUsdtAllowance] = useState<any>(0);
  const [inputStatus, setInputStatus] = useState(true);
  const [receiveTokenWithETH, setReceivedTokenWithETH] = useState<bigint>(0n);
  const [receiveTokenWithUSDT, setReceivedTokenWithUSDT] = useState<bigint>(0n);

  //Waiting window
  const [confirmEth, setConfirmEth] = useState<boolean>(false);
  const [confirmEthSuccess, setConfirmEthSuccess] = useState<number>(0);

  const [approveUsdt, setApproveUsdt] = useState<boolean>(false);
  const [approveUsdtSuccess, setApproveUsdtSuccess] = useState<number>(0);

  const [confirmUsdt, setConfirmUsdt] = useState<boolean>(false);
  const [confirmUsdtSuccess, setConfirmUsdtSuccess] = useState<number>(0);

  const [remainTime, setRemainTime] = useState({
    dd: "00",
    hh: "00",
    mm: "00",
    ss: "00",
  });
  const [gasPrice, setGasPrice] = useState<String>("0");
  const [balanceOfEth, setBalanceOfEth] = useState<bigint>(0n);
  const [balanceOfUsdt, setBalanceOfUsdt] = useState<bigint>(0n);

  // buy token notification
  const [isActiveNotification, setIsActiveNotification] = useState(false);
  const [notificationDone, setNotificationDone] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState<String>("");
  const [estimatedLoadingFlag, setEstimatedLoadingFlag] = useState(false);

  const { data: balanceOfEthData, refetch: refetchEthBalance } = useBalance({
    address: address,
  });

  const { data: balanceOfUsdtData, refetch: refetchUsdtBalance } =
    useReadContract({
      address: usdtAddress,
      abi: erc20abi,
      functionName: "balanceOf",
      args: [address],
    });

  const PresaleContract = {
    address: PresaleCA,
    abi: abi,
    watch: true,
  } as const;

  const publicClient = usePublicClient();

  const { data: startTime, refetch: refetchStartTime } = useReadContract({
    ...PresaleContract,
    functionName: "startTime",
  });
  // console.log("startTime: ", startTime);

  const { data: endTime, refetch: refetchEndTime } = useReadContract({
    ...PresaleContract,
    functionName: "endTime",
  });
  // console.log("endTime: ", endTime);

  const { data: currentStage, refetch: refetchCurrentStage } = useReadContract({
    ...PresaleContract,
    functionName: "currentStep",
  });
  // console.log("currentStage: ", currentStage);

  const { data: currentStagePrice, refetch: refetchCurrentStagePrice } =
    useReadContract({
      ...PresaleContract,
      functionName: "rounds",
      args: [1, currentStage],
    });
  // console.log("currentStagePrice: ", currentStagePrice);

  const { data: currentStageEndTime, refetch: refetchCurrentStageEndTime } =
    useReadContract({
      ...PresaleContract,
      functionName: "rounds",
      args: [2, currentStage],
    });

  // console.log("currentStageEndTime: ", currentStageEndTime);

  const {
    data: latestNativeTokenPrice,
    refetch: refetchLatestNativeTokenPrice,
  } = useReadContract({
    ...PresaleContract,
    functionName: "getLatestPrice",
  });
  // console.log("LatestPrice", latestNativeTokenPrice);

  const { data: totalTokensSold, refetch: refetchTotalTokensSold } =
    useReadContract({
      ...PresaleContract,
      functionName: "totalTokensSold",
    });
  // console.log("totalTokensSold: ", totalTokensSold);

  const { data: totalUSDTRaised, refetch: refetchTotalUSDTRaised } =
    useReadContract({
      ...PresaleContract,
      functionName: "usdRaised",
    });
  // console.log("totalUSDTRaised: ", totalUSDTRaised);

  const { data: purchasedToken, refetch: refetchPurchasedToken } =
    useReadContract({
      ...PresaleContract,
      functionName: "userDeposits",
      args: [address],
    });
  // console.log("purchasedToken: ", purchasedToken);

  const { data: estimatedEth, refetch: refetchEstimatedEth } = useReadContract({
    ...PresaleContract,
    functionName: "ethBuyHelper",
    args: [Math.trunc(valueEthForBuying)],
  });
  // console.log("estimatedEth: ", estimatedEth);

  const { data: estimatedUsdt, refetch: refetchEstimatedUsdt } =
    useReadContract({
      ...PresaleContract,
      functionName: "usdtBuyHelper",
      args: [Math.trunc(valueUsdtForBuying)],
    });
  // console.log("estimatedUsdt: ", estimatedUsdt);

  const readRefetch = async () => {
    await refetchStartTime();
    await refetchEndTime();
    await refetchCurrentStage();
    await refetchCurrentStagePrice();
    await refetchCurrentStageEndTime();
    await refetchTotalTokensSold();
    await refetchTotalUSDTRaised();
    await refetchPurchasedToken();
    await refetchEthBalance();
    await refetchUsdtBalance();
    await refetchLatestNativeTokenPrice();
  };

  const formatNumber = (number: string) => {
    return number.replace(/(\d)(?=(\d{3})+$)/g, "$1,");
  };

  const {
    // data: buyTokenWithEthData,
    writeContractAsync: buyTokenWithEthWrite,
    isPending: buyTokenWithEthIsLoading,
    isSuccess: buyTokenWithEthIsSuccess,
    error: buyTokenWithETHError,
  } = useWriteContract();

  const {
    // data: approveUSDTData,
    writeContractAsync: approveUSDTWrite,
    isPending: approveUSDTIsLoading,
    isSuccess: approveUSDTIsSuccess,
    error: approveUSDTError,
  } = useWriteContract();

  const {
    // data: buyTokenWithUsdtData,
    writeContractAsync: buyTokenWithUsdtWrite,
    isPending: buyTokenWithUsdtIsLoading,
    isSuccess: buyTokenWithUsdtIsSuccess,
    error: buyTokenWithUsdtError,
  } = useWriteContract();

  const {
    // data: buyTokenWithUsdtData,
    writeContractAsync: buyTokenWithUsdtDirectWrite,
    isPending: buyTokenWithUsdtDirectIsLoading,
    isSuccess: buyTokenWithUsdtDirectIsSuccess,
    error: buyTokenWithUsdtDirectError,
  } = useWriteContract();

  const {
    // data: claimTokenData,
    writeContractAsync: claimTokenWrite,
    isPending: claimTokenIsLoading,
    isSuccess: claimTokenIsSuccess,
    error: claimTokenError,
  } = useWriteContract();

  // claim token
  const handleClaimToken = async () => {
    const userPurchasedToken = formatNumber(
      formatEther((purchasedToken || 0n) as bigint)
    );
    console.log(purchasedToken);
    if (Number(purchasedToken) > 0) {
      claimTokenWrite({
        ...PresaleContract,
        functionName: "claim",
      });
      await readRefetch();
    } else {
      console.log("Token will be claimed after presale ends.");
    }
  };

  // const getEstimateGas = async () => {
  //   const data = encodeFunctionData({
  //     ...PresaleContract,
  //     functionName: "buyWithEth",
  //     // value: [parseEther(valueEthForBuying.toString())],
  //   });
  //   let value =
  //     buyMethod === "ETH"
  //       ? Number(valueEthForBuying)
  //       : Number(valueUsdtForBuying);

  //   if (value === 0 || value === undefined || value === null) {
  //     setGasPrice("0");
  //   } else {
  //     try {
  //       const estimatedGas = await publicClient!.estimateGas({
  //         data,
  //         account: address,
  //         to: PresaleCA,
  //         value:
  //           buyMethod === "ETH"
  //             ? parseEther(String(value ? value : 0.00000001))
  //             : parseUnits(String(value ? value : 0.00000001), 6),
  //       });
  //       const gasPrice = await publicClient!.getGasPrice();
  //       setGasPrice(formatEther(estimatedGas * gasPrice));
  //     } catch (error) {
  //       console.log(error);
  //       setGasPrice("Transaction exceeds the balance of the account.");
  //     }
  //   }
  // };

  const handleBuyWithEth = async () => {
    setBuyMethod("ETH");
    setValueCoin(0);
    setEstimatedToken(0);
    setValueEthForBuying(0);
    setValueUsdtForBuying(0);
  };

  const handleBuyWithUsdt = async () => {
    setBuyMethod("USDT");
    setValueCoin(0);
    setEstimatedToken(0);
    setValueEthForBuying(0);
    setValueUsdtForBuying(0);
  };

  //handling message
  const buyTokenLoadingMsg = (textMsg: String) => {
    setIsActiveNotification(true);
    setNotificationMsg(textMsg);
    setTimeout(() => {
      setNotificationDone(false);
    }, 5000);
  };

  const buyTokenSuccessMsg = () => {
    setNotificationDone(true);
    setNotificationMsg("Your transaction has been successfully completed");
    setTimeout(() => {
      setNotificationDone(false);
    }, 5000);
  };

  const connectWalletMsg = () => {
    setIsActiveNotification(true);
    setNotificationMsg("You have to connect wallet for buying Tokens");
    setTimeout(() => {
      setIsActiveNotification(false);
    }, 5000);
  };

  const errorMsg = (errorMsg: String) => {
    setIsActiveNotification(true);
    setNotificationMsg(errorMsg);
    setTimeout(() => {
      setIsActiveNotification(false);
    }, 5000);
  };

  useEffect(() => {
    if (address) {
      // getEstimateGas();
    }
  }, [address, valueEthForBuying, valueUsdtForBuying]);

  useEffect(() => {
    if (endTime === undefined) return;

    const formatTimer = () => {
      const currentTimestamp = Date.now();
      let difference = 0;
      if (Number(startTime) * 1000 > currentTimestamp) {
        difference = Number(startTime) * 1000 - currentTimestamp;
      } else {
        const targetTimestamp = Number(endTime) * 1000; // Convert from seconds to milliseconds
        difference = targetTimestamp - currentTimestamp;
      }

      if (difference > 0) {
        const timeLeft = {
          dd: String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(
            2,
            "0"
          ),
          hh: String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(
            2,
            "0"
          ),
          mm: String(Math.floor((difference / (1000 * 60)) % 60)).padStart(
            2,
            "0"
          ),
          ss: String(Math.floor((difference / 1000) % 60)).padStart(2, "0"),
        };
        return timeLeft;
      } else {
        // Countdown has finished
        return { dd: "00", hh: "00", mm: "00", ss: "00" };
      }
    };

    const updateTimer = () => {
      setRemainTime(formatTimer());
    };

    updateTimer(); // Initial call to set the timer immediately

    const timerId = setInterval(updateTimer, 1000); // Update every second

    return () => clearInterval(timerId); // Cleanup on unmount
  }, [currentStageEndTime]); // Add currentStageEndTime as dependency

  useEffect(() => {
    if (
      buyTokenWithEthIsLoading ||
      claimTokenIsLoading ||
      buyTokenWithUsdtIsLoading ||
      approveUSDTIsLoading
    ) {
      buyTokenLoadingMsg("Transaction Processing. Click “Confirm”.");
    }

    if (
      buyTokenWithEthIsSuccess ||
      buyTokenWithUsdtIsSuccess ||
      claimTokenIsSuccess ||
      approveUSDTIsSuccess
    ) {
      buyTokenSuccessMsg();
    }

    if (buyTokenWithETHError) {
      setIsActiveNotification(false);
      const errorMessage = buyTokenWithETHError.message || "An error occurred.";
      // Find the index of the first period
      const firstPeriodIndex = errorMessage.indexOf(".");
      // Extract the substring until the first period
      const resultMessage =
        firstPeriodIndex !== -1
          ? errorMessage.substring(0, firstPeriodIndex)
          : errorMessage;
      console.log("buyTokenWithETH Error Message", resultMessage); // Log the message until the first period
      errorMsg(resultMessage);
    }

    if (claimTokenError) {
      setIsActiveNotification(false);
      const errorMessage = claimTokenError.message || "An error occurred.";
      // Find the index of the first period
      const firstPeriodIndex = errorMessage.indexOf(".");
      // Extract the substring until the first period
      const resultMessage =
        firstPeriodIndex !== -1
          ? errorMessage.substring(0, firstPeriodIndex)
          : errorMessage;
      console.log("claimToken Error Message", resultMessage); // Log the message until the first period
      errorMsg(resultMessage);
    }

    if (buyTokenWithUsdtError) {
      setIsActiveNotification(false);
      const errorMessage =
        buyTokenWithUsdtError.message || "An error occurred.";
      // Find the index of the first period
      const firstPeriodIndex = errorMessage.indexOf(".");
      // Extract the substring until the first period
      const resultMessage =
        firstPeriodIndex !== -1
          ? errorMessage.substring(0, firstPeriodIndex)
          : errorMessage;
      console.log("buyTokenWithUSDT Error Message", resultMessage); // Log the message until the first period
      errorMsg(resultMessage);
    }

    if (approveUSDTError) {
      setIsActiveNotification(false);
      const errorMessage = approveUSDTError.message || "An error occurred.";
      // Find the index of the first period
      const firstPeriodIndex = errorMessage.indexOf(".");
      // Extract the substring until the first period
      const resultMessage =
        firstPeriodIndex !== -1
          ? errorMessage.substring(0, firstPeriodIndex)
          : errorMessage;
      console.log("approveUSDT Error Message", resultMessage); // Log the message until the first period
      errorMsg(resultMessage);
    }
  }, [
    buyTokenWithETHError,
    claimTokenError,
    buyTokenWithUsdtError,
    approveUSDTError,
    buyTokenWithEthIsSuccess,
    buyTokenWithUsdtIsSuccess,
    claimTokenIsSuccess,
    approveUSDTIsSuccess,
  ]);

  useEffect(() => {
    if (!isConnected) {
      connectWalletMsg();
    }

    if (balanceOfEthData?.value) {
      setBalanceOfEth(balanceOfEthData?.value);
    }

    if (balanceOfUsdtData) {
      setBalanceOfUsdt(balanceOfUsdtData as bigint);
    }
  }, [
    isActiveNotification,
    notificationDone,
    notificationMsg,
    isConnected,
    balanceOfEthData,
    balanceOfUsdtData,
    receiveTokenWithETH,
    receiveTokenWithUSDT,
  ]);
  const handleBuy = async () => {
    if (isConnected) {
      if (buyMethod === "ETH") {
        if (valueEthForBuying !== 0 && buyPosition === "token") {
          try {
            setConfirmEth(true);
            tx = await buyTokenWithEthWrite({
              ...PresaleContract,
              functionName: "buyWithEth",
              args: [valueEthForBuying, false],
              value: estimatedEth as bigint,
            });
            setValueEthForBuying(0);
            setReceivedTokenWithETH(0n);
            await publicClient?.waitForTransactionReceipt({ hash: tx });
            setConfirmEthSuccess(1);
            setTimeout(() => {
              setConfirmEth(false);
              setConfirmEthSuccess(0);
            }, 1000);
            await readRefetch();
          } catch (error) {
            console.error(
              "Error during transaction or function execution:",
              error
            );
            setConfirmEthSuccess(2);
            setTimeout(() => {
              setConfirmEth(false);
              setConfirmEthSuccess(0);
              return;
            }, 1000);
          }
        } else if (estimatedToken !== 0 && buyPosition === "coin") {
          try {
            setConfirmEth(true);
            console.log(estimatedToken);
            tx = await buyTokenWithEthWrite({
              ...PresaleContract,
              functionName: "buyWithEth",
              args: [estimatedToken, false],
              value: parseEther((Number(valueCoin) + 0.001).toString()),
            });
            setValueCoin(0);
            setEstimatedToken(0);
            await publicClient?.waitForTransactionReceipt({ hash: tx });
            setConfirmEthSuccess(1);
            setTimeout(() => {
              setConfirmEth(false);
              setConfirmEthSuccess(0);
            }, 1500);
            await readRefetch();
          } catch (error) {
            console.error(
              "Error during transaction or function execution:",
              error
            );
            setConfirmEthSuccess(2);
            setTimeout(() => {
              setConfirmEth(false);
              setConfirmEthSuccess(0);
              return;
            }, 1500);
          }
        } else {
          console.log("Enter ether amount for buying tokens");
          setInputStatus(false);
        }
      }
      if (buyMethod === "USDT") {
        let hash;
        if (valueUsdtForBuying !== 0 && buyPosition === "token") {
          setApproveUsdt(true);
          let allowance = BigInt(0);
          try {
            allowance = (await publicClient?.readContract({
              address: usdtAddress,
              abi: erc20abi,
              functionName: "allowance",
              args: [address, PresaleCA],
            })) as bigint;
          } catch (err) {
            console.log(err);
            setApproveUsdt(false);
            return;
          }

          console.log("Allow:", allowance, estimatedUsdt);

          if (allowance < Number(estimatedUsdt)) {
            try {
              setApproveUsdtSuccess(0);

              hash = await approveUSDTWrite({
                address: usdtAddress,
                abi: erc20abi,
                functionName: "approve",
                args: [PresaleCA, Number(estimatedUsdt)],
              });

              await publicClient?.waitForTransactionReceipt({ hash: hash! });
              setApproveUsdtSuccess(1);
            } catch (err) {
              console.log(err);
              setApproveUsdtSuccess(2);
              setTimeout(() => {
                setApproveUsdt(false);
                setApproveUsdtSuccess(0);
              }, 1300);
              return;
            }
          }

          setApproveUsdtSuccess(1);

          try {
            setConfirmUsdt(true);
            tx = await buyTokenWithUsdtWrite({
              ...PresaleContract,
              functionName: "buyWithUSDT",
              args: [valueUsdtForBuying, false],
            });

            setValueUsdtForBuying(0);
            setReceivedTokenWithUSDT(0n);

            await publicClient?.waitForTransactionReceipt({ hash: tx });

            setConfirmUsdtSuccess(1);

            setTimeout(() => {
              setConfirmUsdt(false);
              setConfirmUsdtSuccess(0);
              setApproveUsdt(false);
              setApproveUsdtSuccess(0);
            }, 1300);

            await readRefetch();
          } catch (err) {
            setConfirmUsdtSuccess(2);
            setTimeout(() => {
              setConfirmUsdt(false);
              setConfirmUsdtSuccess(0);
              setApproveUsdt(false);
              setApproveUsdtSuccess(0);
            }, 1300);
            return;
          }
        } else if (estimatedToken !== 0 && buyPosition === "coin") {
          setApproveUsdt(true);
          let allowance = BigInt(0);
          try {
            allowance = (await publicClient?.readContract({
              address: usdtAddress,
              abi: erc20abi,
              functionName: "allowance",
              args: [address, PresaleCA],
            })) as bigint;
          } catch (err) {
            console.log(err);
            setApproveUsdt(false);
            return;
          }

          console.log("USDT allowance:", allowance);

          if (
            allowance <
            Number(valueCoin) * 10 ** networkInfo[NETWORK].usdtDecimal
          ) {
            try {
              setApproveUsdtSuccess(0);
              hash = await approveUSDTWrite({
                address: usdtAddress,
                abi: erc20abi,
                functionName: "approve",
                args: [
                  PresaleCA,
                  Number(valueCoin * 10 ** networkInfo[NETWORK].usdtDecimal),
                ],
              });

              await publicClient?.waitForTransactionReceipt({ hash: hash! });
              setApproveUsdtSuccess(1);
            } catch (err) {
              setApproveUsdtSuccess(2);
              setTimeout(() => {
                setApproveUsdt(false);
                setApproveUsdtSuccess(0);
              }, 1300);
              return;
            }
          }

          console.log("Allow:", allowance, estimatedToken);

          setApproveUsdtSuccess(1);

          try {
            setConfirmUsdt(true);
            setConfirmUsdtSuccess(0);
            tx = await buyTokenWithUsdtDirectWrite({
              ...PresaleContract,
              functionName: "buyWithUSDTDirect",
              args: [
                Number(valueCoin * 10 ** networkInfo[NETWORK].usdtDecimal),
                false,
              ],
            });

            setValueCoin(0);
            setEstimatedToken(0);
            await publicClient?.waitForTransactionReceipt({ hash: tx });
            await readRefetch();
            setConfirmUsdtSuccess(1);
            setTimeout(() => {
              setConfirmUsdt(false);
              setConfirmUsdtSuccess(0);
              setApproveUsdt(false);
              setApproveUsdtSuccess(0);
            }, 1300);
          } catch (err) {
            console.log(err);
            setConfirmUsdtSuccess(2);
            setTimeout(() => {
              setConfirmUsdt(false);
              setConfirmUsdtSuccess(0);
              setApproveUsdt(false);
              setApproveUsdtSuccess(0);
            }, 1300);
            return;
          }
        } else {
          console.log("Enter ether amount for buying tokens");
          setInputStatus(false);
        }
      }
    } else {
      connectWalletMsg();
    }
  };

  const onFillMax = () => {
    if (isConnected) {
      if (buyMethod === "ETH") {
        setBuyPosition("coin");
        setValueCoin(
          Number(
            formatterDecimal(
              formatEther(balanceOfEth ? (balanceOfEth as bigint) : 0n),
              4
            )
          )
        );
      }
      if (buyMethod === "USDT") {
        setBuyPosition("coin");

        setValueCoin(
          Number(
            formatterDecimal(
              String(
                formatUnits(balanceOfUsdt, networkInfo[NETWORK].usdtDecimal)
              ),
              4
            )
          )
        );
      }
    } else {
      connectWalletMsg();
    }
  };

  useEffect(() => {
    const updateTokenAmountOnClickMax = async () => {
      if (buyMethod === "ETH") {
        setEstimatedLoadingFlag(true);
        if (currentStagePrice) {
          try {
            const nativeTokenPrice = Number(
              formatEther(latestNativeTokenPrice as bigint)
            );
            setValueCoin(valueCoin);

            // Calculate USD value of input BNB
            const usdValue = valueCoin * nativeTokenPrice;
            // Calculate token amount: USD value / token price
            const tokenAmount =
              usdValue / Number(formatEther(currentStagePrice as bigint));
            setEstimatedToken(Math.trunc(tokenAmount));
          } catch (error) {
            console.error("Error fetching BNB price:", error);
            // Handle error appropriately - maybe show a notification
          }
        }
        setEstimatedLoadingFlag(false);
      } else if (buyMethod === "USDT") {
        setEstimatedLoadingFlag(true);
        setValueCoin(valueCoin);

        if (currentStagePrice) {
          // Calculate token amount directly for USDT
          const tokenAmount =
            valueCoin / Number(formatEther(currentStagePrice as bigint));
          setEstimatedToken(Math.trunc(tokenAmount));
        }
        setEstimatedLoadingFlag(false);
      }
    };
    updateTokenAmountOnClickMax();
  }, [valueCoin]);

  const handleInputForTokenAmount = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBuyPosition("token");
    if (buyMethod === "ETH") {
      setValueEthForBuying(Number(e.target.value));
    }
    if (buyMethod === "USDT") setValueUsdtForBuying(Number(e.target.value));
    setInputStatus(true);
  };

  const handleInputForCoinAmount = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = Number(e.target.value);

    setInputStatus(true);
    setBuyPosition("coin");

    if (buyMethod === "ETH") {
      setEstimatedLoadingFlag(true);
      if (currentStagePrice) {
        try {
          const nativeTokenPrice = Number(
            formatEther(latestNativeTokenPrice as bigint)
          );
          setValueCoin(inputValue);

          // Calculate USD value of input BNB
          const usdValue = inputValue * nativeTokenPrice;
          // Calculate token amount: USD value / token price
          const tokenAmount =
            usdValue / Number(formatEther(currentStagePrice as bigint));
          setEstimatedToken(Math.trunc(tokenAmount));
        } catch (error) {
          console.error("Error fetching BNB price:", error);
        }
      }
      setEstimatedLoadingFlag(false);
    } else if (buyMethod === "USDT") {
      setEstimatedLoadingFlag(true);
      setValueCoin(inputValue);
      if (currentStagePrice) {
        const tokenAmount =
          inputValue / Number(formatEther(currentStagePrice as bigint));
        setEstimatedToken(Math.trunc(tokenAmount));
        console.log(
          "///",
          inputValue,
          currentStagePrice,
          tokenAmount,
          Math.trunc(tokenAmount),
          estimatedToken
        );
      }

      setEstimatedLoadingFlag(false);
    }
  };

  return (
    <div>
      <PendingETH waiting={confirmEth} success={confirmEthSuccess} />
      <PendingUSDT
        approveUsdt={approveUsdt}
        approveUsdtSuccess={approveUsdtSuccess}
        confirmUsdt={confirmUsdt}
        confirmUsdtSuccess={confirmUsdtSuccess}
      />
      {/* {isActiveNotification && (
        <Notification
          notificationDone={notificationDone}
          textMessage={notificationMsg}
        />
      )} */}
      {currentStage == undefined || currentStagePrice == undefined ? (
        <div className="flex justify-center items-center min-h-screen">
          <div className="w-24 h-24 border-4 border-[#0EDB0E] border-t-transparent rounded-full animate-spin"></div>
        </div>
      ) : (
        <div className="max-w-[450px] presale-box relative mt-[320px]">
          {Number(startTime) * 1000 - Date.now() > 0 ? (
            <div className="px-[10px] py-[5px] transition-transform bg-green-500 hover:bg-green-600 text-black shadow-[0_0_20px_rgba(0,255,0,0.3)] button-background-presale font-semibold w-full text-center">
              Pre-sale will start in
            </div>
          ) : (
            <div className="px-[10px] py-[5px] transition-transform bg-green-500 hover:bg-green-600 text-black shadow-[0_0_20px_rgba(0,255,0,0.3)] button-background-presale font-semibold w-full text-center">
              Token Sale Open - Buy TOKEN Now
            </div>
          )}

          <div className="flex flex-col  rounded-b-[30px] px-[26px] pt-[12px] pb-[8px] contact-form border border-[#056b03] default_cursor_cs relative top-[-5px]">
            <CountdownTimer
              days={remainTime.dd}
              hours={remainTime.hh}
              minutes={remainTime.mm}
              seconds={remainTime.ss}
            />
            <div className="text-center">
              <div className=" font-bold text-[10px] sm:text-[14px] mt-[2px]">
                Until token sale ends
              </div>
              <div className="leading-[20px] tracking-[2px] text-[10px] sm:text-[14px]">
                USDT Raised:
                {` $${formatNumber(
                  Number(formatEther(totalUSDTRaised as bigint)).toFixed(2)
                )}/$${formatNumber("6038375.00")}`}
              </div>
              <div className="leading-[13px] text-[10px] sm:text-[14px] ">
                Your Purchased TOKEN ={" "}
                {formatNumber(formatEther((purchasedToken || 0n) as bigint))}
              </div>
            </div>
            <div className="flex text-[#7E8FAA] items-center  bg-none mt-[8px] gap-1 ">
              <div className="h-px flex flex-1 bg-[#315737]"></div>
              <div className="text-[14px]">
                1 TOKEN = $
                {formatNumber(formatEther(currentStagePrice as bigint))} (Phase:{" "}
                {Number(currentStage) + 1})
              </div>
              <div className="h-px flex flex-1 bg-[#315737]"></div>
            </div>
            <div className="py-2 flex justify-between gap-3 flex-wrap tiny:flex-nowrap">
              <button
                className={`px-[30px] tiny:px-[40px]  py-[8px] tiny:py-[10px] rounded-[10px]  border border-green-600  duration-300  w-full sm:w-[150px] ${
                  buyMethod === "ETH"
                    ? "bg-[#3fce3f] text-black"
                    : "bg-black text-[#0EDB0E]"
                }`}
                onClick={() => handleBuyWithEth()}
              >
                <div className="flex items-center justify-center text-center">
                  <img src={ETH} alt="ETH" className="w-[18px] h-[18px] mr-2" />
                  <span className="text-[12px] font-semibold ">ETH</span>
                </div>
              </button>
              <button
                className={`px-[30px] tiny:px-[40px] py-[8px] tiny:py-[10px] rounded-[10px]  border border-green-600  duration-300  w-full sm:w-[150px] ${
                  buyMethod === "ETH"
                    ? "bg-black text-[#0EDB0E]"
                    : "bg-[#3fce3f] text-black"
                }`}
                onClick={() => handleBuyWithUsdt()}
              >
                <div className="flex items-center justify-center text-center">
                  <img
                    src={USDT}
                    alt="USDT"
                    className="w-[18px] h-[18px] mr-2"
                  />
                  <span className="text-[12px] font-semibold ">USDT</span>
                </div>
              </button>
            </div>
            <div className="flex  text-[#7E8FAA] text-xs items-center  bg-none mt-[6px] gap-1">
              <div className="h-px flex flex-1 bg-[#315737]"></div>
              <div className="text-[14px]">
                {buyMethod === "ETH"
                  ? ` ETH Balance : ${formatterDecimal(
                      formatEther(balanceOfEth),
                      4
                    )} ETH`
                  : ` USDT Balance : $${formatNumber(
                      formatUnits(
                        balanceOfUsdt,
                        networkInfo[NETWORK].usdtDecimal
                      )
                    )} `}
              </div>
              <div className="h-px flex flex-1 bg-[#315737]"></div>
            </div>

            <div className="flex flex-col mt-2 gap-1">
              <div className="flex label-box justify-center items-end gap-2">
                <div className="w-full flex-1">
                  <div className="flex justify-between items-end">
                    <label className="text-[10px] tiny:text-[14px]">
                      {buyMethod === "ETH" ? "Pay with ETH" : "Pay with USDT"}
                    </label>
                    <label
                      className="text-[#209482] hover:cursor-pointer text-[10px] tiny:text-[14px]"
                      onClick={onFillMax}
                    >
                      {"MAX"}
                    </label>
                  </div>
                </div>
                <div className="flex text-[10px] tiny:text-[14px] flex-1">
                  <label>{"Receive TOKEN"}</label>
                </div>
              </div>
              <div className="flex gap-2">
                <div>
                  <div className="relative inline-block w-[100%]">
                    <input
                      className={`w-full h-[40px] bg-black outline-none border 
                          ${
                            inputStatus
                              ? "border-[#056b03]"
                              : "border-[#ff0000]"
                          }
                          rounded-[5px] p-[3px] pr-12
                          ${
                            (
                              buyMethod === "ETH"
                                ? estimatedEth === undefined
                                : estimatedUsdt === undefined
                            )
                              ? blurredInputClass
                              : ""
                          }`}
                      type="number"
                      step="1"
                      value={
                        buyPosition === "coin"
                          ? valueCoin
                          : buyMethod === "ETH"
                          ? estimatedEth !== undefined
                            ? formatEther(estimatedEth as bigint)
                            : ""
                          : estimatedUsdt !== undefined
                          ? formatUnits(
                              estimatedUsdt as bigint,
                              networkInfo[NETWORK].usdtDecimal
                            )
                          : ""
                      }
                      onChange={handleInputForCoinAmount}
                      placeholder="Enter amount"
                      disabled={
                        buyMethod === "ETH"
                          ? estimatedEth === undefined
                          : estimatedUsdt === undefined
                      }
                    />
                    <img
                      className="absolute right-3 top-[50%] translate-y-[-50%] w-[15px] h-[15px]"
                      src={buyMethod === "ETH" ? ETH : USDT}
                      alt="ETH icon"
                    />
                  </div>
                </div>
                <div>
                  <div className=" relative inline-block w-[100%]">
                    <input
                      className={`
                          w-full h-[40px] bg-black outline-none border 
                        border-[#056b03]
                          rounded-[5px] p-[3px] pr-12
                          ${estimatedLoadingFlag ? blurredInputClass : ""}
                        `}
                      type="number"
                      step="1"
                      value={
                        buyPosition === "token"
                          ? buyMethod === "ETH"
                            ? valueEthForBuying
                            : valueUsdtForBuying
                          : formatNumber(Number(estimatedToken).toFixed(2))
                      }
                      onChange={handleInputForTokenAmount}
                      placeholder="Enter amount"
                    />
                    <img
                      className="absolute right-3 top-[50%] translate-y-[-50%] w-[15px] h-[15px]"
                      src={EVLK}
                      alt="USDT icon"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex  text-[#7E8FAA] text-xs items-center  bg-none mt-2">
              <div className="h-px flex flex-1 bg-[#315737]"></div>
            </div>

            <div>
              <div className="px-2 pt-2 pb-2 text-[12px] tiny:text-[14px] text-center flex m-auto">
                {`${formatterDecimal(
                  String(gasPrice),
                  7
                )} ETH is reserved for gas fees. The actual amount used will depend on the network activity at the time`}
              </div>
              <WidgetProgressBar
                currentValue={Number(totalTokensSold)}
                maxValue={70000000000}
              />
              <div className="flex flex-wrap justify-center gap-5 mt-2">
                <div className="flex-1">
                  <ConnectButton.Custom>
                    {({
                      account,
                      chain,
                      openAccountModal,
                      openChainModal,
                      openConnectModal,
                      authenticationStatus,
                      mounted,
                    }) => {
                      // Note: If your app doesn't use authentication, you
                      // can remove all 'authenticationStatus' checks
                      const ready =
                        mounted && authenticationStatus !== "loading";
                      const connected =
                        ready &&
                        account &&
                        chain &&
                        (!authenticationStatus ||
                          authenticationStatus === "authenticated");
                      return (
                        <div
                          className="w-full"
                          {...(!ready && {
                            "aria-hidden": true,
                            style: {
                              opacity: 0,
                              pointerEvents: "none",
                              userSelect: "none",
                            },
                          })}
                        >
                          {(() => {
                            if (!connected) {
                              return (
                                <button
                                  className="bg-black px-[16px] tiny:px-[24px] py-[8px] tiny:py-[12px] rounded-[10px]  border border-green-600 hover:bg-[#3fce3f] duration-300 text-[#0EDB0E] hover:text-[#000000] w-full"
                                  onClick={openConnectModal}
                                >
                                  <div className="flex items-center justify-center text-center">
                                    <img
                                      src={EVLK}
                                      alt="USDT"
                                      className="w-[24px] h-[24px] mr-2"
                                    />
                                    <span className="text-[14px] font-semibold whitespace-nowrap">
                                      Connect Wallet
                                    </span>
                                  </div>
                                </button>
                              );
                            }
                            if (chain.unsupported) {
                              return (
                                <button onClick={openChainModal} type="button">
                                  Wrong network
                                </button>
                              );
                            }
                            return (
                              <button
                                className="bg-black px-[14px] tiny:px-[24px] py-[8px] tiny:py-[12px]  rounded-[10px]  border border-green-600 hover:bg-[#3fce3f] duration-300 text-[#0EDB0E] hover:text-[#000000] w-full"
                                onClick={handleBuy}
                                disabled={Number(startTime) * 1000 > Date.now()}
                              >
                                <div className="flex items-center justify-center text-center">
                                  <img
                                    src={EVLK}
                                    alt="USDT"
                                    className="w-[24px] h-[24px] mr-2"
                                  />
                                  <span className="text-[12px] font-semibold ">
                                    {Number(startTime) * 1000 > Date.now()
                                      ? "Wait for Sale on"
                                      : "Buy TOKENS"}
                                  </span>
                                </div>
                              </button>
                            );
                          })()}
                        </div>
                      );
                    }}
                  </ConnectButton.Custom>
                </div>

                {isConnected && (
                  <div className="flex-1">
                    <button
                      className="bg-black  px-[16px] tiny:px-[24px] py-[8px] tiny:py-[12px]  rounded-[10px]  border border-green-600 hover:bg-[#3fce3f] duration-300 text-[#0EDB0E] hover:text-[#000000] w-full disabled:text-[#26a731] disabled:cursor-not-allowed"
                      disabled={
                        endTime !== undefined &&
                        typeof endTime === "bigint" &&
                        Number(endTime) * 1000 - Date.now() > 0
                      }
                      onClick={() => handleClaimToken()}
                    >
                      {endTime !== undefined &&
                      typeof endTime === "bigint" &&
                      Number(endTime) * 1000 - Date.now() < 0 ? (
                        <div className="flex items-center justify-center text-center">
                          <img
                            src={EVLK}
                            alt="USDT"
                            className="w-[24px] h-[24px] mr-2"
                          />
                          <span className="text-[14px] font-semibold whitespace-nowrap">
                            Claim Tokens
                          </span>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center text-center">
                          <img
                            src={EVLK}
                            alt="USDT"
                            className="w-[24px] h-[24px] mr-2"
                          />
                          <span className="text-[14px] font-semibold whitespace-nowrap">
                            Wait for claim
                          </span>
                        </div>
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="text-[12px] font-semibold mt-[8px] flex flex-col tiny:flex-row justify-center items-center gap-[6px] justify-center">
              <span className="opacity-60">Embrace Your Inner Evil</span>{" "}
              <span className="text-[#0EDB0E]">Invest In EvilKermit.</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Presale;
