// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.presale-box {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding-top: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.button-background-presale {
  box-shadow: rgb(118 139 126) 0px 50px 100px -20px,
    rgb(81 44 44 / 99%) 0px 30px 60px -30px, rgb(0 2 0) 0px -5px 4px 1px inset,
    rgb(133, 240, 133) -1px 5px 4px 1px inset;
  background: linear-gradient(to bottom, #0ab80a, #36ce36);
  border-radius: 10px;
  width: 101%;
  z-index: 8;
  font-size: 13px;
}

@media only screen and (max-width: 470px) {
  .presale-box {
    padding-top: 10px;
  }
  .button-background-presale {
    font-size: 13px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Presale.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,4BAA4B;EAC5B,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE;;6CAE2C;EAC3C,wDAAwD;EACxD,mBAAmB;EACnB,WAAW;EACX,UAAU;EACV,eAAe;AACjB;;AAEA;EACE;IACE,iBAAiB;EACnB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".presale-box {\n  border-top-right-radius: 10px;\n  border-top-left-radius: 10px;\n  padding-top: 30px;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n}\n\n.button-background-presale {\n  box-shadow: rgb(118 139 126) 0px 50px 100px -20px,\n    rgb(81 44 44 / 99%) 0px 30px 60px -30px, rgb(0 2 0) 0px -5px 4px 1px inset,\n    rgb(133, 240, 133) -1px 5px 4px 1px inset;\n  background: linear-gradient(to bottom, #0ab80a, #36ce36);\n  border-radius: 10px;\n  width: 101%;\n  z-index: 8;\n  font-size: 13px;\n}\n\n@media only screen and (max-width: 470px) {\n  .presale-box {\n    padding-top: 10px;\n  }\n  .button-background-presale {\n    font-size: 13px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
