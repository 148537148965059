import "../App.css";

interface CountdownTimerProps {
  days: String;
  hours: String;
  minutes: String;
  seconds: String;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  days,
  hours,
  minutes,
  seconds,
}) => {
  return (
    <>
      <div className="flex justify-center md:gap-[8px]">
        <div className="flex flex-col justify-center gap-[3px] items-center">
          <div className="logofont timerback px-0 py-0 w-[50px] h-[50px]">
            <span className="text-[30px] leading-[80px] ">{days}</span>
          </div>
          <span className="inter-uniquifier text-[12px] md:text-[12px] font-normal leading-[24px] text-[#C5DED1] text-center">
            DAYS
          </span>
        </div>

        <div className="text-[30px]">:</div>

        <div className="flex flex-col justify-center gap-[3px] items-center">
          <div className="logofont timerback px-0 py-0 w-[50px] h-[50px]">
            <span className="text-[30px]  leading-[80px] ">{hours}</span>
          </div>
          <span className="inter-uniquifier text-[12px] md:text-[12px] font-normal leading-[24px] text-[#C5DED1] text-center">
            HOURS
          </span>
        </div>
        <div className="text-[30px]">:</div>

        <div className="flex flex-col justify-center gap-[3px] items-center">
          <div className="logofont timerback px-0 py-0 w-[50px] h-[50px]">
            <span className="text-[30px]  leading-[80px] ">{minutes}</span>
          </div>
          <span className="inter-uniquifier text-[12px] md:text-[12px] font-normal leading-[24px] text-[#C5DED1] text-center">
            MINUTES
          </span>
        </div>
        <div className="text-[30px]">:</div>

        <div className="flex flex-col justify-center gap-[3px] items-center">
          <div className="logofont timerback px-0 py-0 w-[50px] h-[50px]">
            <span className="text-[30px]  leading-[80px] ">{seconds}</span>
          </div>
          <span className="inter-uniquifier text-[12px] md:text-[12px] font-normal leading-[24px] text-[#C5DED1] text-center">
            SECONDS
          </span>
        </div>
      </div>
    </>
  );
};

export default CountdownTimer;
