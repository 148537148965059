import React, { forwardRef } from "react";
import "./Presale.css";
const phases = [
  {
    title: "Phase 1",
    from: "Jan 20 -",
    to: "- Feb 3, 2025",
    price: "$0.00005",
    textX: "Maximum discount",
    textY: "exclusive bonuses",
  },
  {
    title: "Phase 2",
    from: "Feb 3 -",
    to: "- Feb 17, 2025",
    price: "$0.00005625",
    textX: "Early access ",
    textY: "rewards still available.",
  },
  {
    title: "Phase 3",
    from: "Feb 17 -",
    to: "- Mar 3, 2025",
    price: "$0.0000625",
    textX: "Final",
    textY: "low-price window.",
  },
  {
    title: "Phase 4",
    from: "Mar 3 -",
    to: "- Mar 17, 2025",
    price: "$0.0000725",
    textX: "Limited supply",
    textY: "before public launch!",
  },
];
const Presale = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div
      ref={ref}
      className="px-0 md:px-[100px] xl:px-[150px] flex flex-col gap-[50px]"
    >
      <div className="flex flex-col">
        <div className="font-normal flex flex-wrap justify-center items-center gap-2 tiny:gap-5">
          <div className="relative text-[35px] tiny:text-[40px] sm:text-[75px] ">
            <h1 className="titlered-shadow absolute top-0 left-0 w-full whitespace-nowrap">
              Get in Early
            </h1>
            <h1 className="titlered z-10 whitespace-nowrap">Get in Early</h1>
          </div>
        </div>
        <div className="font-normal flex flex-wrap justify-center items-center gap-2 tiny:gap-5">
          <div className="relative text-[26px] tiny:text-[35px] sm:text-[45px] md:text-[55px] lg:text-[65px] xl:text-[75px] ">
            <h1 className="text-green-500 titlegreen whitespace-nowrap">
              Join the $EVLK Presale
            </h1>
            <h1 className="text-green-500 titlegreen-shadow absolute top-0 left-0 w-full whitespace-nowrap">
              Join the $EVLK Presale
            </h1>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          {phases.map((phase, index) => {
            return (
              <div className="flex phase-card" key={"Phase" + index}>
                <div className="w-[140px] tiny:w-[180px]">
                  <h1 className="text-[18px] tiny:text-[25px]">
                    {phase.title}
                  </h1>
                  <p className="text-[13px] tiny:text-[16px] whitespace-nowrap text-[#adadad]">
                    {phase.textX}
                  </p>
                  <p className="text-[13px] tiny:text-[16px] whitespace-nowrap text-[#adadad]">
                    {phase.textY}
                  </p>
                </div>
                <div
                  style={{
                    borderLeft: "2px solid #333", // Defines the line thickness and color
                    height: "100px", // Make the line as tall as the parent element
                    width: "2px",
                    margin: "0 10px", // Optional: Adds spacing around the line
                  }}
                ></div>
                <div className="w-[90px] tiny:w-[150px] flex flex-col gap-1 justify-center">
                  <h1 className="text-[13px] tiny:text-[18px] text-[#7ab979]">
                    {phase.from}
                  </h1>
                  <h1 className="text-[13px] tiny:text-[18px] text-[#7ab979]">
                    {phase.to}
                  </h1>
                  <h1 className="text-[13px] tiny:text-[20px]">
                    {phase.price}
                  </h1>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="text-center">
        <a className="link-a">Join the Presale Now!</a>
      </div>
    </div>
  );
});

export default Presale;
