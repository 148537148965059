import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useAccount } from "wagmi";

import React, { useRef } from "react";

import "./App.css";
import Header from "./page/header";
import Tokenomics from "./page/ChartPart";
import RoadMap from "./page/RoadMap";
import Presale from "./page/Presale";
import EvilKermitBuy from "./page/HowToBuy";
import Community from "./page/Community";
import FootPage from "./page/Footpage";
import Navbar from "./page/Navbar";
import ThemeStyles from "./assets/styles/ThemeStyles";
import { ThemeProvider } from "styled-components";

import "@rainbow-me/rainbowkit/styles.css";
import "./App.css";
import "react-multi-carousel/lib/styles.css";
import NFTSection from "./page/NFTSection";
import MobileRoadMap from "./page/MobileRoadMap";
import About from "./page/About";
import { Fade } from "react-awesome-reveal";
import { FADE_DURATION, FADE_DELAY } from "./config";
import RoadmapNew from "./page/RoadmapNew";
import Whitepaper from "./page/Whitepaper";
import Contact from "./page/Contact";
function App() {
  const section1Ref = useRef<HTMLDivElement | null>(null);
  const section2Ref = useRef<HTMLDivElement | null>(null);
  const section3Ref = useRef<HTMLDivElement | null>(null);
  const section4Ref = useRef<HTMLDivElement | null>(null);
  const section5Ref = useRef<HTMLDivElement | null>(null);
  const section6Ref = useRef<HTMLDivElement | null>(null);
  const section7Ref = useRef<HTMLDivElement | null>(null);
  const section8Ref = useRef<HTMLDivElement | null>(null);
  const section9Ref = useRef<HTMLDivElement | null>(null);

  return (
    <ThemeProvider theme={ThemeStyles}>
      <div className="w-full body-background text-white relative overflow-hidden flex flex-col itemss-center">
        <Navbar
          section1Ref={section1Ref}
          section2Ref={section2Ref}
          section3Ref={section3Ref}
          section4Ref={section4Ref}
          section5Ref={section5Ref}
          section6Ref={section6Ref}
          section7Ref={section7Ref}
          section8Ref={section8Ref}
          section9Ref={section9Ref}
        />
        <Fade cascade duration={FADE_DURATION} delay={FADE_DELAY}>
          <div>
            <Header ref={section1Ref} />
          </div>
        </Fade>
        <Fade cascade duration={FADE_DURATION} delay={FADE_DELAY}>
          <div>
            <Presale ref={section3Ref} />
          </div>
        </Fade>
        <Fade cascade duration={FADE_DURATION} delay={FADE_DELAY}>
          <div>
            <About ref={section2Ref} />
          </div>
        </Fade>
        <Fade cascade duration={FADE_DURATION} delay={FADE_DELAY}>
          <div>
            <Tokenomics ref={section4Ref} />
          </div>
        </Fade>
        <Fade cascade duration={FADE_DURATION} delay={FADE_DELAY}>
          <div className="lg:hidden">
            <MobileRoadMap ref={section5Ref} />
          </div>
        </Fade>
        <Fade cascade duration={FADE_DURATION} delay={FADE_DELAY}>
          <div className="hidden lg:block">
            <RoadMap ref={section5Ref} />
          </div>
        </Fade>

        {/* <Fade cascade duration={FADE_DURATION} delay={FADE_DELAY}>
          <div>
            <RoadmapNew ref={section5Ref} />
          </div>
        </Fade> */}
        <Fade cascade duration={FADE_DURATION} delay={FADE_DELAY}>
          <div>
            <NFTSection ref={section6Ref} />
          </div>
        </Fade>

        <Fade cascade duration={FADE_DURATION} delay={FADE_DELAY}>
          <div>
            <Whitepaper ref={section7Ref} />
          </div>
        </Fade>

        {/* <Fade cascade duration={FADE_DURATION} delay={FADE_DELAY}>
          <div>
            <EvilKermitBuy ref={section6Ref} />
          </div>
        </Fade> */}
        <Fade cascade duration={FADE_DURATION} delay={FADE_DELAY}>
          <div>
            <Community ref={section8Ref} />
          </div>
        </Fade>
        <Fade cascade duration={FADE_DURATION} delay={FADE_DELAY}>
          <div>
            <Contact ref={section9Ref} />
          </div>
        </Fade>

        <Fade cascade duration={FADE_DURATION} delay={FADE_DELAY}>
          <FootPage />
        </Fade>
      </div>
    </ThemeProvider>
  );
}

export default App;
