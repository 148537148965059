import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import EVLK from "../assets/images/presale-mark.png";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
// import { Fade } from "react-awesome-reveal";
import { Fade } from "@mui/material";

type PendingProps = {
  waiting: boolean;
  success: number; //  0: normal, 1: success, 2: failed
};
const PendingETH = ({ waiting, success }: PendingProps) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "#272727",
    border: "1px solid #454545",
    borderRadius: "5px",
    color: "#00d51c",
    outline: 0,
    boxShadow: 24,
    p: 1,
    pl: 4,
    textAlign: "center",
  };

  const buttonSx = {
    display: "flex",
    justifyContent: "center",
    ...(success == 1 && {
      bgcolor: green[500],
    }),
    ...(success == 2 && {
      bgcolor: "#ff0000",
    }),
  };
  return (
    <Modal
      open={waiting}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Fade in={waiting} timeout={400}>
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box sx={{ m: 1, position: "relative" }}>
              <Fab
                aria-label="save"
                color="primary"
                size="small"
                sx={buttonSx}
                // onClick={handleButtonClick}
              >
                {success == 1 && <CheckIcon fontSize="small" />}
                {success == 0 && (
                  <img src={EVLK} alt="USDT" className="w-[36px] h-[36px]" />
                )}
                {success == 2 && <PriorityHighIcon fontSize="small" />}
              </Fab>
              {waiting && success == 0 && (
                <CircularProgress
                  size={48}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: -4,
                    left: -4,
                    zIndex: 1,
                  }}
                />
              )}
            </Box>
            <Typography id="keep-mounted-modal-description">
              {waiting && success == 0 && "Confirming Transaction"}
              {success == 1 && "Confirmed Transaction"}
              {success == 2 && "Transaction failed"}
            </Typography>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default PendingETH;
