import "./NFTCardNew.css";

type NFTType = {
  type: string;
  source: any;
  title: string;
  description: string;
  tagline: string;
};

interface NFTCardProps {
  nft: NFTType;
}

const NFTCardNew = ({ nft }: NFTCardProps) => {
  return (
    <div className="nft">
      <div className="main flex justify-center">
        {nft.type == "image" ? (
          <img src={nft.source} alt="nft card" className="tokenImage" />
        ) : (
          <video autoPlay loop muted className="tokenImage ">
            <source src={nft.source} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
        {/* <img className="tokenImage" src={nft.source} alt="NFT" /> */}
        <h2 className="nft-card-title">{nft.title}</h2>
        <p className="description">{nft.description}</p>
        <div className="tokenInfo">
          <div className="price">
            {/* <ins>◘</ins> */}
            {/* <p>0.031 ETH</p> */}
          </div>
          <div className="duration">
            {/* <ins>◷</ins> */}
            {/* <p>11 days left</p> */}
          </div>
        </div>
        {/* <hr /> */}
        {/* <div className="creator">
          <div className="wrapper">
            <img
              src="https://images.unsplash.com/photo-1620121692029-d088224ddc74?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80"
              alt="Creator"
            />
          </div>
          <p>
            <ins>Creation of</ins> Kiberbash
            {nft.tagline}
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default NFTCardNew;
